import RealWorldTreatmentOutcomesService from 'src/modules/realWorldTreatmentOutcomes/realWorldTreatmentOutcomesService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'REALWORLDTREATMENTOUTCOMES_FORM';

const realWorldTreatmentOutcomesFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  // doInit: (id) => async (dispatch) => {
  //   try {
  //     dispatch({
  //       type: realWorldTreatmentOutcomesFormActions.INIT_STARTED,
  //     });

  //     let record = {};

  //     const isEdit = Boolean(id);

  //     if (isEdit) {
  //       record = await RealWorldTreatmentOutcomesService.find(id);
  //     }

  //     dispatch({
  //       type: realWorldTreatmentOutcomesFormActions.INIT_SUCCESS,
  //       payload: record,
  //     });
  //   } catch (error) {
  //     Errors.handle(error);

  //     dispatch({
  //       type: realWorldTreatmentOutcomesFormActions.INIT_ERROR,
  //     });

  //     getHistory().push('/real-world-treatment-outcomes');
  //   }
  // },

  // doCreate: (values) => async (dispatch) => {
  //   try {
  //     dispatch({
  //       type: realWorldTreatmentOutcomesFormActions.CREATE_STARTED,
  //     });

  //     await RealWorldTreatmentOutcomesService.create(values);

  //     dispatch({
  //       type: realWorldTreatmentOutcomesFormActions.CREATE_SUCCESS,
  //     });

  //     Message.success(
  //       i18n('entities.realWorldTreatmentOutcomes.create.success'),
  //     );

  //     getHistory().push('/real-world-treatment-outcomes');
  //   } catch (error) {
  //     Errors.handle(error);

  //     dispatch({
  //       type: realWorldTreatmentOutcomesFormActions.CREATE_ERROR,
  //     });
  //   }
  // },

  // doUpdate: (id, values) => async (dispatch, getState) => {
  //   try {
  //     dispatch({
  //       type: realWorldTreatmentOutcomesFormActions.UPDATE_STARTED,
  //     });

  //     await RealWorldTreatmentOutcomesService.update(id, values);

  //     dispatch({
  //       type: realWorldTreatmentOutcomesFormActions.UPDATE_SUCCESS,
  //     });

  //     Message.success(
  //       i18n('entities.realWorldTreatmentOutcomes.update.success'),
  //     );

  //     getHistory().push('/real-world-treatment-outcomes');
  //   } catch (error) {
  //     Errors.handle(error);

  //     dispatch({
  //       type: realWorldTreatmentOutcomesFormActions.UPDATE_ERROR,
  //     });
  //   }
  // },
};

export default realWorldTreatmentOutcomesFormActions;
