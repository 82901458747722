import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/realWorldTreatmentOutcomes2/importer/realWorldTreatmentOutcomes2ImporterSelectors';
import RealWorldTreatmentOutcomes2Service from 'src/modules/realWorldTreatmentOutcomes2/realWorldTreatmentOutcomes2Service';
import fields from 'src/modules/realWorldTreatmentOutcomes2/importer/realWorldTreatmentOutcomes2ImporterFields';
import { i18n } from 'src/i18n';

const realWorldTreatmentOutcomes2ImporterActions = importerActions(
  'REALWORLDTREATMENTOUTCOMES2_IMPORTER',
  selectors,
  RealWorldTreatmentOutcomes2Service.import,
  fields,
  i18n('entities.realWorldTreatmentOutcomes2.importer.fileName'),
);

export default realWorldTreatmentOutcomes2ImporterActions;