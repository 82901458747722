import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.searchStrategyRwo.fields.id'),
  },
  {
    name: 'terms',
    label: i18n('entities.searchStrategyRwo.fields.terms'),
  },
  {
    name: 'medline',
    label: i18n('entities.searchStrategyRwo.fields.medline'),
  },
  {
    name: 'embase',
    label: i18n('entities.searchStrategyRwo.fields.embase'),
  },
  {
    name: 'ext1',
    label: i18n('entities.searchStrategyRwo.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.searchStrategyRwo.fields.ext2'),
  },
  {
    name: 'ext3',
    label: i18n('entities.searchStrategyRwo.fields.ext3'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.searchStrategyRwo.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.searchStrategyRwo.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
