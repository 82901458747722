import list from 'src/modules/patientBurden/list/patientBurdenListReducers';
import form from 'src/modules/patientBurden/form/patientBurdenFormReducers';
import view from 'src/modules/patientBurden/view/patientBurdenViewReducers';
import destroy from 'src/modules/patientBurden/destroy/patientBurdenDestroyReducers';
import importerReducer from 'src/modules/patientBurden/importer/patientBurdenImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
