import React, { useEffect, useContext } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { useSelector } from 'react-redux'
import { CssBaseline, createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import { SnackbarProvider, useSnackbar } from 'notistack'
import 'typeface-roboto'

import { configureStore, getHistory } from 'src/modules/store'
import authSelectors from 'src/modules/auth/authSelectors'
import settingsThemeConverter from 'src/modules/settings/settingsThemeConverter'
import Message from 'src/view/shared/message'
import RoutesComponent from 'src/view/shared/routes/RoutesComponent'
import Login from 'src/Login/Login'
import AuthContext from 'src/store/auth-context'

const store = configureStore()

export default function App(props) {
	const ctx = useContext(AuthContext);

	return (
		<Provider store={store}>
			{!ctx.isLoggedIn && <Login />}
			{ctx.isLoggedIn && <AppWithRedux {...props} />}
		</Provider>
	)
}

function AppWithRedux(props) {
	const settings = useSelector(authSelectors.selectCurrentSettings)
	const colors = settingsThemeConverter.fromString(settings && settings.theme)
	const theme = createMuiTheme(
		{
			palette:
			{
				primary: { main: colors.primary },
				secondary: { main: colors.secondary }
			},
			typography: { fontSize: 12.5 }
		})

	return (
		<MuiThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={3}>
				<>
					<CssBaseline />
					<AppWithSnackbar {...props} />
				</>
			</SnackbarProvider>
		</MuiThemeProvider>
	)
}

function AppWithSnackbar(props) {
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		// A little hack to not have to import notistack on all the components that emit messages

		Message.registerNotistakEnqueueSnackbar(enqueueSnackbar)
	}, [enqueueSnackbar])

	return (
		<>
			<ConnectedRouter history={getHistory()}>
				<RoutesComponent />
			</ConnectedRouter>
		</>
	)
}
