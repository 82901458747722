const realWorldOutcomesEnumerators = {
  regionSel: [
    'Africa',
    'AsiaPacific',
    'Australia',
    'Europe',
    'LatinAmerica',
    'MiddleEast',
    'USA',
    'Canada',
  ],
  yearSel: ['2015', '2016', '2017', '2018', '2019', '2020'],
  patientPopSel: ['CLL', 'rrCll'],
  dataSourceSel: [
    'ClaimsDatabase',
    'eap',
    'Literature',
    'MedicalCenters',
    'Registry',
  ],
  outcomeSel: [
    'Response',
    'OverallSurvival',
    'ProgressionFreeSurvival',
    'AdverseEvents',
    'Adherence',
    'Discontinuation',
    'QoL',
  ],
  drugSel: [
    'Acalabrutinib',
    'Bendamustine',
    'Duvelisib',
    'Ibrutinib',
    'Idelalisib',
    'Lenalidomide',
    'Obinutuzumab',
    'Ofatumumab',
    'Venetoclax',
  ],
};

export default realWorldOutcomesEnumerators;
