import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.economic.fields.id'),
  },
  {
    name: 'study',
    label: i18n('entities.economic.fields.study'),
  },
  {
    name: 'sponsor',
    label: i18n('entities.economic.fields.sponsor'),
  },
  {
    name: 'publicationType',
    label: i18n('entities.economic.fields.publicationType'),
  },
  {
    name: 'setting',
    label: i18n('entities.economic.fields.setting'),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.economic.fields.patientPopulation'),
  },
  {
    name: 'outcomes',
    label: i18n('entities.economic.fields.outcomes'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.economic.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.economic.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
