import list from 'src/modules/searchDataEconomic/list/searchDataEconomicListReducers';
import form from 'src/modules/searchDataEconomic/form/searchDataEconomicFormReducers';
import view from 'src/modules/searchDataEconomic/view/searchDataEconomicViewReducers';
import destroy from 'src/modules/searchDataEconomic/destroy/searchDataEconomicDestroyReducers';
import importerReducer from 'src/modules/searchDataEconomic/importer/searchDataEconomicImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
