import React, { useImperativeHandle, useRef } from 'react';
import styles from './FormControl.module.scss';

const FormControl = React.forwardRef((props, ref) => {
  const inputRef = useRef();

  const activate = () => {
    inputRef.current.focus();
  };

  useImperativeHandle(ref, () => {
    return {
      focus: activate
    }
  });

  return (
    <div className={
      `${styles.FormControl} ${props.isValid === false ?
        styles.invalid : ''}`
    }>
      <label htmlFor={props.id}>{props.label}</label>
      <input
        ref={inputRef}
        id={props.id}
        type={props.type || 'input'}
        value={props.value || ''}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </div>
  );
});

export default FormControl;