import list from 'src/modules/economicBurden/list/economicBurdenListReducers';
import form from 'src/modules/economicBurden/form/economicBurdenFormReducers';
import view from 'src/modules/economicBurden/view/economicBurdenViewReducers';
import destroy from 'src/modules/economicBurden/destroy/economicBurdenDestroyReducers';
import importerReducer from 'src/modules/economicBurden/importer/economicBurdenImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
