import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/searchDataEconomic/importer/searchDataEconomicImporterSelectors';
import SearchDataEconomicService from 'src/modules/searchDataEconomic/searchDataEconomicService';
import fields from 'src/modules/searchDataEconomic/importer/searchDataEconomicImporterFields';
import { i18n } from 'src/i18n';

const searchDataEconomicImporterActions = importerActions(
  'SEARCHDATAECONOMIC_IMPORTER',
  selectors,
  SearchDataEconomicService.import,
  fields,
  i18n('entities.searchDataEconomic.importer.fileName'),
);

export default searchDataEconomicImporterActions;