import list from 'src/modules/resourceUtilization/list/resourceUtilizationListReducers';
import form from 'src/modules/resourceUtilization/form/resourceUtilizationFormReducers';
import view from 'src/modules/resourceUtilization/view/resourceUtilizationViewReducers';
import destroy from 'src/modules/resourceUtilization/destroy/resourceUtilizationDestroyReducers';
import importerReducer from 'src/modules/resourceUtilization/importer/resourceUtilizationImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
