import SearchStrategyService from 'src/modules/searchStrategies/searchStrategyService';
//import selectors from 'src/modules/searchStrategy/list/searchStrategyListSelectors';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'SEARCHSTRATEGY_LIST';

const searchStrategyListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  doFetch: (
    filter?,
    rawFilter?,
    keepPagination = false,
  ) => async (dispatch, getState) => {
    try {
      dispatch({
        type: searchStrategyListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      console.log('searchStrategyListActions.tsx - 23');
      console.log('filter:');
      console.log(filter);
      const response = await SearchStrategyService.list(
        filter,
      );

      dispatch({
        type: searchStrategyListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: searchStrategyListActions.FETCH_ERROR,
      });
    }
  },
};

export default searchStrategyListActions;
