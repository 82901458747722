import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class IncidenceService {
  // Cleanup
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/incidence/${id}`,
      body,
    );

    return response.data;
  }

  // static async destroyAll(ids) {
  //   const params = {
  //     ids,
  //   };

  //   const tenantId = AuthCurrentTenant.get();

  //   const response = await authAxios.delete(
  //     `/tenant/${tenantId}/incidence`,
  //     {
  //       params,
  //     },
  //   );

  //   return response.data;
  // }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/incidence`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/incidence/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/incidence/${id}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/incidence`,
      {
        params,
      },
    );

    //return response.data;
    return { rows: response.data, count: 1 };
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/incidence/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }
}
