import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'author',
    label: i18n('entities.realWorldTreatmentOutcomes2.fields.author'),
    schema: schemas.string(
      i18n('entities.realWorldTreatmentOutcomes2.fields.author'),
      {},
    ),
  },
  {
    name: 'year',
    label: i18n('entities.realWorldTreatmentOutcomes2.fields.year'),
    schema: schemas.string(
      i18n('entities.realWorldTreatmentOutcomes2.fields.year'),
      {},
    ),
  },
  {
    name: 'publicationType',
    label: i18n('entities.realWorldTreatmentOutcomes2.fields.publicationType'),
    schema: schemas.string(
      i18n('entities.realWorldTreatmentOutcomes2.fields.publicationType'),
      {},
    ),
  },
  {
    name: 'funder',
    label: i18n('entities.realWorldTreatmentOutcomes2.fields.funder'),
    schema: schemas.string(
      i18n('entities.realWorldTreatmentOutcomes2.fields.funder'),
      {},
    ),
  },
  {
    name: 'country',
    label: i18n('entities.realWorldTreatmentOutcomes2.fields.country'),
    schema: schemas.string(
      i18n('entities.realWorldTreatmentOutcomes2.fields.country'),
      {},
    ),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.realWorldTreatmentOutcomes2.fields.patientPopulation'),
    schema: schemas.string(
      i18n('entities.realWorldTreatmentOutcomes2.fields.patientPopulation'),
      {},
    ),
  },
  {
    name: 'cea',
    label: i18n('entities.realWorldTreatmentOutcomes2.fields.cea'),
    schema: schemas.string(
      i18n('entities.realWorldTreatmentOutcomes2.fields.cea'),
      {},
    ),
  },
  {
    name: 'bim',
    label: i18n('entities.realWorldTreatmentOutcomes2.fields.bim'),
    schema: schemas.string(
      i18n('entities.realWorldTreatmentOutcomes2.fields.bim'),
      {},
    ),
  },
  {
    name: 'other',
    label: i18n('entities.realWorldTreatmentOutcomes2.fields.other'),
    schema: schemas.string(
      i18n('entities.realWorldTreatmentOutcomes2.fields.other'),
      {},
    ),
  },
  {
    name: 'ext1',
    label: i18n('entities.realWorldTreatmentOutcomes2.fields.ext1'),
    schema: schemas.string(
      i18n('entities.realWorldTreatmentOutcomes2.fields.ext1'),
      {},
    ),
  },
  {
    name: 'ext2',
    label: i18n('entities.realWorldTreatmentOutcomes2.fields.ext2'),
    schema: schemas.string(
      i18n('entities.realWorldTreatmentOutcomes2.fields.ext2'),
      {},
    ),
  },
  {
    name: 'ext3',
    label: i18n('entities.realWorldTreatmentOutcomes2.fields.ext3'),
    schema: schemas.string(
      i18n('entities.realWorldTreatmentOutcomes2.fields.ext3'),
      {},
    ),
  },
];