import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/uiComparativeEffectiveness/importer/uiComparativeEffectivenessImporterSelectors';
import UiComparativeEffectivenessService from 'src/modules/uiComparativeEffectiveness/uiComparativeEffectivenessService';
import fields from 'src/modules/uiComparativeEffectiveness/importer/uiComparativeEffectivenessImporterFields';
import { i18n } from 'src/i18n';

const uiComparativeEffectivenessImporterActions = importerActions(
  'UICOMPARATIVEEFFECTIVENESS_IMPORTER',
  selectors,
  UiComparativeEffectivenessService.import,
  fields,
  i18n('entities.uiComparativeEffectiveness.importer.fileName'),
);

export default uiComparativeEffectivenessImporterActions;