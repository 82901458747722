const secondaryCancersEnumerators = {
 /* regionSel: [
    'Africa',
    'AsiaPacific',
    'Australia',
    'Europe',
    'LatinAmerica',
    'MiddleEast',
    'USA',
    'Canada',
  ],*/
  METHODOLOGY: [
    'Prospective',
    'Retrospective',
  ],
  OUTCOMES:[
    'Bleeding',	
'Kidney-Related Outcomes',	
'LVEF',	
'Mortality'	,
'Myocardial Infarction',
'NYHA',
'Paravalvular leak (PVL)',
'Permanent Pacemaker',	
'Procedure/Device Success',	
'Stroke',	
'Valve in Valve',	
'Vascular Complication',	


  ],
  SEARCHDATABASE:[
    'Chinese',
          'English'  
  ],
  STUDYSETTING:[
    'Anhui',
'Beijing',
'Bengbu',
'Changsha',
'Dalian',
'Daye',
'Guangzhou',
'Hangzhou',
'Henan',
'Hong Kong',
'Kunming',
'Leshan',
'Qingdao',

  ],

  yearSel: ['2012','2013','2014','2015', '2016', '2017', '2018', '2019', '2020','2021','2022'],
  patientPopSel: ['CLL', 'rrCll'],
  dataSourceSel: [
    'ClaimsDatabase',
    'eap',
    'Literature',
    'MedicalCenters',
    'Registry',
  ],
};

export default secondaryCancersEnumerators;
