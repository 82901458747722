
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import AuthContext from '../store/auth-context';
import FormControl from '../UI/FormControl/FormControl';
import { i18n } from 'src/i18n';
import classes from './Login.module.scss';
import {
  Card,
  Button,
  Toolbar,
  makeStyles
} from '@material-ui/core';

const tenantReducer = (state, action) => {
  const t = "f4341b65-7fcf-4b3f-ab3d-2add968260ee"
  if (action.type === 'USER_INPUT') {
    return {
      value: action.val,
      isValid: action.val === t,
    };
  }
  if (action.type === 'INPUT_BLUR') {
    return {
      value: state.value,
      isValid: state.value
    };
  }
  return {
    value: '',
    isValid: null
  };
};

const passwordReducer = (state, action) => {
  const p = "tenant123"
  if (action.type === 'USER_INPUT') {
    return {
      value: action.val,
      isValid: action.val === p,
    };
  }
  if (action.type === 'INPUT_BLUR') {
    return {
      value: state.value,
      isValid: state.value.trim().length > 6
    };
  }
  return {
    value: '',
    isValid: null
  };
};

const Login = () => {

  const [formIsValid, setFormIsValid] = useState(false);

  const [tenantState, dispatchTenant] = useReducer(tenantReducer, {
    value: '',
    isValid: null
  });

  const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
    value: '',
    isValid: null
  });

  const authCtx = useContext(AuthContext);

  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const { isValid: emailIsValid } = tenantState;
  const { isValid: passwordIsValid } = passwordState;

  useEffect(() => {
    const identifier = setTimeout(() => {
      // console.log('Checking form validity');
      setFormIsValid(
        emailIsValid && passwordIsValid
      );
    }, 500);

    return () => {
      // NOT runs first useEffect call and this runs before every useEffect() works (like do while condition works)
      // console.log('CLEANUP');
      clearTimeout(identifier);
    };
  }, [emailIsValid, passwordIsValid]);

  const emailChangeHandler = (event) => {
    dispatchTenant({
      type: 'USER_INPUT',
      val: event.target.value
    });

    // setFormIsValid(
    //   emailState.isValid && passwordState.isValid
    // );
  };

  const passwordChangeHandler = (event) => {
    // setEnteredPassword(event.target.value);

    dispatchPassword({
      type: 'USER_INPUT',
      val: event.target.value
    });

    // setFormIsValid(
    //   emailState.isValid && passwordState.isValid
    // );
  };

  const validateEmailHandler = () => {
    // setEmailIsValid(emailState.isValid);
    dispatchTenant({
      type: 'INPUT_BLUR'
    });
  };

  const validatePasswordHandler = () => {
    // setPasswordIsValid(enteredPassword.trim().length > 6);
    dispatchTenant({
      type: 'INPUT_BLUR'
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (formIsValid) {
      authCtx.onLogin(tenantState.value, passwordState.value);
      AuthCurrentTenant.set(tenantState.value);
    } else if (!emailIsValid) {
      emailInputRef.current.focus();
    } else {
      passwordInputRef.current.focus();
    }
  };
  const useStyles = makeStyles((theme) => ({
    appBar: {
      paddingBottom: 40,
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.getContrastText(
        theme.palette.primary.main,
      ),
    },
    logo: {
      fontWeight: 500,
      fontSize: '1.5em',
      textDecoration: 'none',
      paddingLeft: theme.spacing(1),
      color: theme.palette.getContrastText(
        theme.palette.primary.main,
      ),
    },
    grow: {
      flex: '1 1 auto',
    },
  }));
  return (
    <React.Fragment>
      <Toolbar>
        <h1 className={classes.h1}>{i18n('app.title')}</h1>
      </Toolbar>

      <Card className={classes.login} >
        <div className={classes.header}>
          <b>SIGN-IN</b>
        </div>
        <form onSubmit={submitHandler}>
          <FormControl
            id="tenant"
            type="text"
            label="Tenant"
            ref={emailInputRef}
            isValid={emailIsValid}
            value={tenantState.value}
            onBlur={validateEmailHandler}
            onChange={emailChangeHandler}
          />

          <FormControl
            id="password"
            type="password"
            label="Password"
            ref={passwordInputRef}
            isValid={passwordIsValid}
            value={passwordState.value}
            onBlur={validatePasswordHandler}
            onChange={passwordChangeHandler}
          />

          {/* <div className={`${classes.control} ${emailState.isValid === false ? classes.invalid : ''}`}>
            <label htmlFor="email">E-Mail</label>
            <input
              id="email"
              type="email"
              value={emailState.value}
              onChange={emailChangeHandler}
              onBlur={validateEmailHandler}
            />
          </div>
          <div className={`${classes.control} ${passwordState.isValid === false ? classes.invalid : ''}`}>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={passwordState.value}
              onChange={passwordChangeHandler}
              onBlur={validatePasswordHandler}
            />
          </div> */}

          <div className={classes.actions}>
            <Button type="submit" color="primary" className={classes.btn}>
              Login
            </Button>
          </div>
        </form>

        {/* <hr />
      <p><strong>Username:</strong> demo@demo</p>
      <p><strong>Password:</strong> 1234567</p>

      <blockquote>
        Any valid email &amp; password with a length of 7
      </blockquote> */}
      </Card>
    </React.Fragment>
  );
};

export default Login;
