import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.patientBurden.fields.id'),
  },
  {
    name: 'regionsSel',
    label: i18n('entities.patientBurden.fields.regionsSel'),
  },
  {
    name: 'yearSel',
    label: i18n('entities.patientBurden.fields.yearSel'),
  },
  {
    name: 'patientPopSel',
    label: i18n(
      'entities.patientBurden.fields.patientPopSel',
    ),
  },
  {
    name: 'study',
    label: i18n('entities.patientBurden.fields.study'),
  },
  {
    name: 'year',
    label: i18n('entities.patientBurden.fields.year'),
  },
  {
    name: 'studyType',
    label: i18n('entities.patientBurden.fields.studyType'),
  },
  {
    name: 'country',
    label: i18n('entities.patientBurden.fields.country'),
  },
  {
    name: 'data',
    label: i18n('entities.patientBurden.fields.data'),
  },
  {
    name: 'timePeriod',
    label: i18n('entities.patientBurden.fields.timePeriod'),
  },
  {
    name: 'patientPopulation',
    label: i18n(
      'entities.patientBurden.fields.patientPopulation',
    ),
  },
  {
    name: 'measures',
    label: i18n('entities.patientBurden.fields.measures'),
  },
  {
    name: 'results',
    label: i18n('entities.patientBurden.fields.results'),
  },
  {
    name: 'count',
    label: i18n('entities.patientBurden.fields.count'),
  },
  {
    name: 'rowIsVisible',
    label: i18n(
      'entities.patientBurden.fields.rowIsVisible',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rowColor',
    label: i18n('entities.patientBurden.fields.rowColor'),
  },
  {
    name: 'africa',
    label: i18n('entities.patientBurden.fields.africa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asiaPacific',
    label: i18n(
      'entities.patientBurden.fields.asiaPacific',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'australia',
    label: i18n('entities.patientBurden.fields.australia'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'europe',
    label: i18n('entities.patientBurden.fields.europe'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'latinAmerica',
    label: i18n(
      'entities.patientBurden.fields.latinAmerica',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'middleEast',
    label: i18n('entities.patientBurden.fields.middleEast'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'usa',
    label: i18n('entities.patientBurden.fields.usa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'canada',
    label: i18n('entities.patientBurden.fields.canada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result',
    label: i18n('entities.patientBurden.fields.result'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'year',
    label: i18n('entities.patientBurden.fields.year'),
  },
  {
    name: 'result2',
    label: i18n('entities.patientBurden.fields.result2'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'cll',
    label: i18n('entities.patientBurden.fields.cll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rrCll',
    label: i18n('entities.patientBurden.fields.rrCll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result3',
    label: i18n('entities.patientBurden.fields.result3'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n('entities.patientBurden.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.patientBurden.fields.ext2'),
  },
  {
    name: 'ext3',
    label: i18n('entities.patientBurden.fields.ext3'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.patientBurden.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.patientBurden.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
