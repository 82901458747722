import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'terms',
    label: i18n('entities.searchStrategyRwo.fields.terms'),
    schema: schemas.string(
      i18n('entities.searchStrategyRwo.fields.terms'),
      {},
    ),
  },
  {
    name: 'medline',
    label: i18n('entities.searchStrategyRwo.fields.medline'),
    schema: schemas.string(
      i18n('entities.searchStrategyRwo.fields.medline'),
      {},
    ),
  },
  {
    name: 'embase',
    label: i18n('entities.searchStrategyRwo.fields.embase'),
    schema: schemas.string(
      i18n('entities.searchStrategyRwo.fields.embase'),
      {},
    ),
  },
  {
    name: 'ext1',
    label: i18n('entities.searchStrategyRwo.fields.ext1'),
    schema: schemas.string(
      i18n('entities.searchStrategyRwo.fields.ext1'),
      {},
    ),
  },
  {
    name: 'ext2',
    label: i18n('entities.searchStrategyRwo.fields.ext2'),
    schema: schemas.string(
      i18n('entities.searchStrategyRwo.fields.ext2'),
      {},
    ),
  },
  {
    name: 'ext3',
    label: i18n('entities.searchStrategyRwo.fields.ext3'),
    schema: schemas.string(
      i18n('entities.searchStrategyRwo.fields.ext3'),
      {},
    ),
  },
];