import list from 'src/modules/prognosticCharacteristics/list/prognosticCharacteristicsListReducers';
// import form from 'src/modules/prognosticCharacteristics/form/prognosticCharacteristicsFormReducers';
// import view from 'src/modules/prognosticCharacteristics/view/prognosticCharacteristicsViewReducers';
// import destroy from 'src/modules/prognosticCharacteristics/destroy/prognosticCharacteristicsDestroyReducers';
// import importerReducer from 'src/modules/prognosticCharacteristics/importer/prognosticCharacteristicsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  // form,
  // view,
  // destroy,
  // importer: importerReducer,
});
