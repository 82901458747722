import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.searchDataEconomic.fields.id'),
  },
  {
    name: 'terms',
    label: i18n('entities.searchDataEconomic.fields.terms'),
  },
  {
    name: 'medline',
    label: i18n('entities.searchDataEconomic.fields.medline'),
  },
  {
    name: 'embase',
    label: i18n('entities.searchDataEconomic.fields.embase'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.searchDataEconomic.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.searchDataEconomic.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
