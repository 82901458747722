import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.realWorldOutcomes.fields.id'),
  },
  {
    name: 'area',
    label: i18n('entities.realWorldOutcomes.fields.area'),
  },
  {
    name: 'subarea',
    label: i18n('entities.realWorldOutcomes.fields.subarea'),
  },
  {
    name: 'regionSel',
    label: i18n('entities.realWorldOutcomes.fields.regionSel'),
  },
  {
    name: 'yearSel',
    label: i18n('entities.realWorldOutcomes.fields.yearSel'),
  },
  {
    name: 'patientPopSel',
    label: i18n('entities.realWorldOutcomes.fields.patientPopSel'),
  },
  {
    name: 'dataSourceSel',
    label: i18n('entities.realWorldOutcomes.fields.dataSourceSel'),
  },
  {
    name: 'outcomeSel',
    label: i18n('entities.realWorldOutcomes.fields.outcomeSel'),
  },
  {
    name: 'drugSel',
    label: i18n('entities.realWorldOutcomes.fields.drugSel'),
  },
  {
    name: 'author',
    label: i18n('entities.realWorldOutcomes.fields.author'),
  },
  {
    name: 'year',
    label: i18n('entities.realWorldOutcomes.fields.year'),
  },
  {
    name: 'studyType',
    label: i18n('entities.realWorldOutcomes.fields.studyType'),
  },
  {
    name: 'funder',
    label: i18n('entities.realWorldOutcomes.fields.funder'),
  },
  {
    name: 'country',
    label: i18n('entities.realWorldOutcomes.fields.country'),
  },
  {
    name: 'data',
    label: i18n('entities.realWorldOutcomes.fields.data'),
  },
  {
    name: 'timePeriod',
    label: i18n('entities.realWorldOutcomes.fields.timePeriod'),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.realWorldOutcomes.fields.patientPopulation'),
  },
  {
    name: 'interventions',
    label: i18n('entities.realWorldOutcomes.fields.interventions'),
  },
  {
    name: 'outcomes',
    label: i18n('entities.realWorldOutcomes.fields.outcomes'),
  },
  {
    name: 'results',
    label: i18n('entities.realWorldOutcomes.fields.results'),
  },
  {
    name: 'count',
    label: i18n('entities.realWorldOutcomes.fields.count'),
  },
  {
    name: 'rowIsVisible',
    label: i18n('entities.realWorldOutcomes.fields.rowIsVisible'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rowColor',
    label: i18n('entities.realWorldOutcomes.fields.rowColor'),
  },
  {
    name: 'africa',
    label: i18n('entities.realWorldOutcomes.fields.africa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asiaPacific',
    label: i18n('entities.realWorldOutcomes.fields.asiaPacific'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'australia',
    label: i18n('entities.realWorldOutcomes.fields.australia'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'europe',
    label: i18n('entities.realWorldOutcomes.fields.europe'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'latinAmerica',
    label: i18n('entities.realWorldOutcomes.fields.latinAmerica'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'middleEast',
    label: i18n('entities.realWorldOutcomes.fields.middleEast'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'usa',
    label: i18n('entities.realWorldOutcomes.fields.usa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'canada',
    label: i18n('entities.realWorldOutcomes.fields.canada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result',
    label: i18n('entities.realWorldOutcomes.fields.result'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'value',
    label: i18n('entities.realWorldOutcomes.fields.value'),
  },
  {
    name: 'result2',
    label: i18n('entities.realWorldOutcomes.fields.result2'),
  },
  {
    name: 'cll',
    label: i18n('entities.realWorldOutcomes.fields.cll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rrCll',
    label: i18n('entities.realWorldOutcomes.fields.rrCll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result3',
    label: i18n('entities.realWorldOutcomes.fields.result3'),
  },
  {
    name: 'claimsDatabase',
    label: i18n('entities.realWorldOutcomes.fields.claimsDatabase'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'eap',
    label: i18n('entities.realWorldOutcomes.fields.eap'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'literature',
    label: i18n('entities.realWorldOutcomes.fields.literature'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'medicalCenters',
    label: i18n('entities.realWorldOutcomes.fields.medicalCenters'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'registry',
    label: i18n('entities.realWorldOutcomes.fields.registry'),
  },
  {
    name: 'result4',
    label: i18n('entities.realWorldOutcomes.fields.result4'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'responseVal',
    label: i18n('entities.realWorldOutcomes.fields.responseVal'),
  },
  {
    name: 'response',
    label: i18n('entities.realWorldOutcomes.fields.response'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'overallSurvival',
    label: i18n('entities.realWorldOutcomes.fields.overallSurvival'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'progressionFreeSurvival',
    label: i18n('entities.realWorldOutcomes.fields.progressionFreeSurvival'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'adverseEvents',
    label: i18n('entities.realWorldOutcomes.fields.adverseEvents'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'adherence',
    label: i18n('entities.realWorldOutcomes.fields.adherence'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'discontinuation',
    label: i18n('entities.realWorldOutcomes.fields.discontinuation'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'qol',
    label: i18n('entities.realWorldOutcomes.fields.qol'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result5',
    label: i18n('entities.realWorldOutcomes.fields.result5'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'acalabrutinib',
    label: i18n('entities.realWorldOutcomes.fields.acalabrutinib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'bendamustine',
    label: i18n('entities.realWorldOutcomes.fields.bendamustine'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'duvelisib',
    label: i18n('entities.realWorldOutcomes.fields.duvelisib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ibrutinib',
    label: i18n('entities.realWorldOutcomes.fields.ibrutinib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'idelalisib',
    label: i18n('entities.realWorldOutcomes.fields.idelalisib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'lenalidomide',
    label: i18n('entities.realWorldOutcomes.fields.lenalidomide'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'obinutuzumab',
    label: i18n('entities.realWorldOutcomes.fields.obinutuzumab'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ofatumumab',
    label: i18n('entities.realWorldOutcomes.fields.ofatumumab'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'venetoclax',
    label: i18n('entities.realWorldOutcomes.fields.venetoclax'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result6',
    label: i18n('entities.realWorldOutcomes.fields.result6'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n('entities.realWorldOutcomes.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.realWorldOutcomes.fields.ext2'),
  },
  {
    name: 'ext3',
    label: i18n('entities.realWorldOutcomes.fields.ext3'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.realWorldOutcomes.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.realWorldOutcomes.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
