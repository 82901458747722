import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'study',
    label: i18n('entities.patient.fields.study'),
    schema: schemas.string(
      i18n('entities.patient.fields.study'),
      {},
    ),
  },
  {
    name: 'sponsor',
    label: i18n('entities.patient.fields.sponsor'),
    schema: schemas.string(
      i18n('entities.patient.fields.sponsor'),
      {},
    ),
  },
  {
    name: 'publicationType',
    label: i18n('entities.patient.fields.publicationType'),
    schema: schemas.string(
      i18n('entities.patient.fields.publicationType'),
      {},
    ),
  },
  {
    name: 'country',
    label: i18n('entities.patient.fields.country'),
    schema: schemas.string(
      i18n('entities.patient.fields.country'),
      {},
    ),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.patient.fields.patientPopulation'),
    schema: schemas.string(
      i18n('entities.patient.fields.patientPopulation'),
      {},
    ),
  },
  {
    name: 'measures',
    label: i18n('entities.patient.fields.measures'),
    schema: schemas.string(
      i18n('entities.patient.fields.measures'),
      {},
    ),
  },
];