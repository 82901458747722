import RealWorldTreatmentOutcomesService from 'src/modules/realWorldTreatmentOutcomes/realWorldTreatmentOutcomesService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'REALWORLDTREATMENTOUTCOMES_VIEW';

const realWorldTreatmentOutcomesViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  // doFind: (id) => async (dispatch) => {
  //   try {
  //     dispatch({
  //       type: realWorldTreatmentOutcomesViewActions.FIND_STARTED,
  //     });

  //     const record = await RealWorldTreatmentOutcomesService.find(id);

  //     dispatch({
  //       type: realWorldTreatmentOutcomesViewActions.FIND_SUCCESS,
  //       payload: record,
  //     });
  //   } catch (error) {
  //     Errors.handle(error);

  //     dispatch({
  //       type: realWorldTreatmentOutcomesViewActions.FIND_ERROR,
  //     });

  //     getHistory().push('/real-world-treatment-outcomes');
  //   }
  // },
};

export default realWorldTreatmentOutcomesViewActions;
