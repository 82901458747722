import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.costs.fields.id'),
  },
  {
    name: 'area',
    label: i18n('entities.costs.fields.area'),
  },
  {
    name: 'subarea',
    label: i18n('entities.costs.fields.subarea'),
  },
  {
    name: 'regionSel',
    label: i18n('entities.costs.fields.regionSel'),
  },
  {
    name: 'yearSel',
    label: i18n('entities.costs.fields.yearSel'),
  },
  {
    name: 'patientPopSel',
    label: i18n('entities.costs.fields.patientPopSel'),
  },
  {
    name: 'dataSourceSel',
    label: i18n('entities.costs.fields.dataSourceSel'),
  },
  {
    name: 'outcomeSel',
    label: i18n('entities.costs.fields.outcomeSel'),
  },
  {
    name: 'drugSel',
    label: i18n('entities.costs.fields.drugSel'),
  },
  {
    name: 'author',
    label: i18n('entities.costs.fields.author'),
  },
  {
    name: 'year',
    label: i18n('entities.costs.fields.year'),
  },
  {
    name: 'publicationType',
    label: i18n('entities.costs.fields.publicationType'),
  },
  {
    name: 'studyType',
    label: i18n('entities.costs.fields.studyType'),
  },
  {
    name: 'funder',
    label: i18n('entities.costs.fields.funder'),
  },
  {
    name: 'country',
    label: i18n('entities.costs.fields.country'),
  },
  {
    name: 'data',
    label: i18n('entities.costs.fields.data'),
  },
  {
    name: 'timePeriod',
    label: i18n('entities.costs.fields.timePeriod'),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.costs.fields.patientPopulation'),
  },
  {
    name: 'interventions',
    label: i18n('entities.costs.fields.interventions'),
  },
  {
    name: 'outcomes',
    label: i18n('entities.costs.fields.outcomes'),
  },
  {
    name: 'results',
    label: i18n('entities.costs.fields.results'),
  },
  {
    name: 'count',
    label: i18n('entities.costs.fields.count'),
  },
  {
    name: 'rowIsVisible',
    label: i18n('entities.costs.fields.rowIsVisible'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rowColor',
    label: i18n('entities.costs.fields.rowColor'),
  },
  {
    name: 'author2',
    label: i18n('entities.costs.fields.author2'),
  },
  {
    name: 'africa',
    label: i18n('entities.costs.fields.africa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asiaPacific',
    label: i18n('entities.costs.fields.asiaPacific'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'australia',
    label: i18n('entities.costs.fields.australia'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'europe',
    label: i18n('entities.costs.fields.europe'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'latinAmerica',
    label: i18n('entities.costs.fields.latinAmerica'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'middleEast',
    label: i18n('entities.costs.fields.middleEast'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'usa',
    label: i18n('entities.costs.fields.usa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'canada',
    label: i18n('entities.costs.fields.canada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result',
    label: i18n('entities.costs.fields.result'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'valueDate',
    label: i18n('entities.costs.fields.valueDate'),
  },
  {
    name: 'result2',
    label: i18n('entities.costs.fields.result2'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'cll',
    label: i18n('entities.costs.fields.cll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rrCll',
    label: i18n('entities.costs.fields.rrCll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result3',
    label: i18n('entities.costs.fields.result3'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'claimsDatabase',
    label: i18n('entities.costs.fields.claimsDatabase'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'eap',
    label: i18n('entities.costs.fields.eap'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'literature',
    label: i18n('entities.costs.fields.literature'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'medicalCenters',
    label: i18n('entities.costs.fields.medicalCenters'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'registry',
    label: i18n('entities.costs.fields.registry'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result4',
    label: i18n('entities.costs.fields.result4'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'outcome',
    label: i18n('entities.costs.fields.outcome'),
  },
  {
    name: 'outcomeTotal',
    label: i18n('entities.costs.fields.outcomeTotal'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'inpatient',
    label: i18n('entities.costs.fields.inpatient'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'acalabrutinib',
    label: i18n('entities.costs.fields.acalabrutinib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'bendamustine',
    label: i18n('entities.costs.fields.bendamustine'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'duvelisib',
    label: i18n('entities.costs.fields.duvelisib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ibrutinib',
    label: i18n('entities.costs.fields.ibrutinib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'idelalisib',
    label: i18n('entities.costs.fields.idelalisib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'lenalidomide',
    label: i18n('entities.costs.fields.lenalidomide'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'obinutuzumab',
    label: i18n('entities.costs.fields.obinutuzumab'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ofatumumab',
    label: i18n('entities.costs.fields.ofatumumab'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'venetoclax',
    label: i18n('entities.costs.fields.venetoclax'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result5',
    label: i18n('entities.costs.fields.result5'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n('entities.costs.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.costs.fields.ext2'),
  },
  {
    name: 'ext3',
    label: i18n('entities.costs.fields.ext3'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.costs.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.costs.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
