
import PermissionChecker from 'src/modules/auth/permissionChecker'
import Layout from 'src/view/layout/Layout'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

function PublicRoute({ component: Component, currentTenant, currentUser, ...rest })
{
	return (
	    <Route
		{...rest}
		render={(props) =>
		{
			const permissionChecker = new PermissionChecker( currentTenant, currentUser )

			if (permissionChecker.isAuthenticated)
				return <Redirect to="/" />
									// return <Component {...props} />
			return (
				<Layout {...props}>
					<Component {...props} />
				</Layout>
			)
		}}
	    />
	)
}

export default PublicRoute

