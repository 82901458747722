import SecondaryCancersService from 'src/modules/secondaryCancers/secondaryCancersService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'SECONDARYCANCERS_VIEW';

const secondaryCancersViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: secondaryCancersViewActions.FIND_STARTED,
      });

      const record = await SecondaryCancersService.find(id);

      dispatch({
        type: secondaryCancersViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: secondaryCancersViewActions.FIND_ERROR,
      });

      getHistory().push('/secondary-cancers');
    }
  },
};

export default secondaryCancersViewActions;
