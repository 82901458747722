const modelsEnumerators = {
  regionSel: [
    'Africa',
    'AsiaPacific',
    'Australia',
    'Europe',
    'LatinAmerica',
    'MiddleEast',
    'USA',
    'Canada',
  ],
  yearSel: ['2015', '2016', '2017', '2018', '2019', '2020'],
  patientPopSel: ['CLL', 'rrCll'],
  drugSel: [
    'Acalabrutinib',
    'Bendamustine',
    'Duvelisib',
    'Ibrutinib',
    'Idelalisib',
    'Lenalidomide',
    'Obinutuzumab',
    'Ofatumumab',
    'Venetoclax',
  ],
  modelTypeSel: ['ceaModel', 'bimModel'],
};

export default modelsEnumerators;
