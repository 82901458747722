import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/resourceUtilization/importer/resourceUtilizationImporterSelectors';
import ResourceUtilizationService from 'src/modules/resourceUtilization/resourceUtilizationService';
import fields from 'src/modules/resourceUtilization/importer/resourceUtilizationImporterFields';
import { i18n } from 'src/i18n';

const resourceUtilizationImporterActions = importerActions(
  'RESOURCEUTILIZATION_IMPORTER',
  selectors,
  ResourceUtilizationService.import,
  fields,
  i18n('entities.resourceUtilization.importer.fileName'),
);

export default resourceUtilizationImporterActions;