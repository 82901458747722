import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.secondaryCancers.fields.id'),
  },
  {
    name: '  METHODOLOGY',
    label: i18n('entities.secondaryCancers.fields.  METHODOLOGY'),
  },
  {
    name: 'yearSel',
    label: i18n('entities.secondaryCancers.fields.yearSel'),
  },
  {
    name: 'OUTCOMES',
    label: i18n('entities.secondaryCancers.fields.OUTCOMES'),
  },
  {
    name: 'SEARCHDATABASE',
    label: i18n('entities.secondaryCancers.fields.SEARCHDATABASE'),
  },
  {
    name: 'STUDYSETTING',
    label: i18n('entities.secondaryCancers.fields.STUDYSETTING'),
  },
  {
    name: 'dataSourceSel',
    label: i18n('entities.secondaryCancers.fields.dataSourceSel'),
  },
  {
    name: 'author',
    label: i18n('entities.secondaryCancers.fields.author'),
  },
  {
    name: 'Year',
    label: i18n('entities.secondaryCancers.fields.Year'),
    
  },
 
  {
    name: 'Methodology',
    label: i18n('entities.secondaryCancers.fields.Methodology'),
    
  },
  {
    name: 'studysetting',
    label: i18n('entities.secondaryCancers.fields.studysetting'),
    
  },
  {
    name: 'DataSource',
    label: i18n('entities.secondaryCancers.fields.DataSource'),
    
  },
  {
    name: 'StudyTimeline',
    label: i18n('entities.secondaryCancers.fields.StudyTimeline'),
    
  },
  {
    name: 'InclusionCriteria',
    label: i18n('entities.secondaryCancers.fields.InclusionCriteria'),
    
  },
  {
    name: 'ExclusionCriteria',
    label: i18n('entities.secondaryCancers.fields.ExclusionCriteria'),
    
  },
  {
    name: 'StudyPopulation',
    label: i18n('entities.secondaryCancers.fields.StudyPopulation'),
    
  },
  {
    name: 'Samplesize',
    label: i18n('entities.secondaryCancers.fields.Samplesize'),
    
  },
  {
    name: 'Age',
    label: i18n('entities.secondaryCancers.fields.Age'),
    
  },
  {
    name: 'Gender',
    label: i18n('entities.secondaryCancers.fields.Gender'),
    
  },
  {
    name: 'Stsscoresurgicalrisk',
    label: i18n('entities.secondaryCancers.fields.Stsscoresurgicalrisk'),
    
  },
  {
    name: 'FollowUp',
    label: i18n('entities.secondaryCancers.fields.FollowUp'),
    
  }, {
    name: 'Interventions',
    label: i18n('entities.secondaryCancers.fields.Interventions'),
    
  },
  {
    name: 'EndpointCategory',
    label: i18n('entities.secondaryCancers.fields.EndpointCategory'),
    
  },



  {
    name: 'EndpointDefinition',
    label: i18n('entities.secondaryCancers.fields.EndpointDefinition'),
  },
  {
    name: 'Result',
    label: i18n('entities.secondaryCancers.fields.Result'),
  },
  {
    name: 'country',
    label: i18n('entities.secondaryCancers.fields.DataSource'),
  },
  {
    name: 'data',
    label: i18n('entities.secondaryCancers.fields.StudyTimeline'),
  },
  {
    name: 'timePeriod',
    label: i18n('entities.secondaryCancers.fields.Studysamplesize'),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.secondaryCancers.fields.patientPopulation'),
  },
  {
    name: 'secondaryCancer',
    label: i18n('entities.secondaryCancers.fields.secondaryCancer'),
  },
  {
    name: 'endpoints',
    label: i18n('entities.secondaryCancers.fields.endpoints'),
  },
  {
    name: 'results',
    label: i18n('entities.secondaryCancers.fields.results'),
  },
  {
    name: 'count',
    label: i18n('entities.secondaryCancers.fields.count'),
  },
  {
    name: 'rowIsVisible',
    label: i18n('entities.secondaryCancers.fields.rowIsVisible'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rowColor',
    label: i18n('entities.secondaryCancers.fields.rowColor'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'africa',
    label: i18n('entities.secondaryCancers.fields.africa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asiaPacific',
    label: i18n('entities.secondaryCancers.fields.asiaPacific'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'australia',
    label: i18n('entities.secondaryCancers.fields.australia'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'europe',
    label: i18n('entities.secondaryCancers.fields.europe'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'latinAmerica',
    label: i18n('entities.secondaryCancers.fields.latinAmerica'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'middleEast',
    label: i18n('entities.secondaryCancers.fields.middleEast'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'usa',
    label: i18n('entities.secondaryCancers.fields.usa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'canada',
    label: i18n('entities.secondaryCancers.fields.canada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result',
    label: i18n('entities.secondaryCancers.fields.result'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'value',
    label: i18n('entities.secondaryCancers.fields.value'),
  },
  {
    name: 'result2',
    label: i18n('entities.secondaryCancers.fields.result2'),
  },
  {
    name: 'cll',
    label: i18n('entities.secondaryCancers.fields.cll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rrCll',
    label: i18n('entities.secondaryCancers.fields.rrCll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result3',
    label: i18n('entities.secondaryCancers.fields.result3'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'claimsDatabase',
    label: i18n('entities.secondaryCancers.fields.claimsDatabase'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'eap',
    label: i18n('entities.secondaryCancers.fields.eap'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'literature',
    label: i18n('entities.secondaryCancers.fields.literature'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'medicalCenters',
    label: i18n('entities.secondaryCancers.fields.medicalCenters'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'registry',
    label: i18n('entities.secondaryCancers.fields.registry'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result4',
    label: i18n('entities.secondaryCancers.fields.result4'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n('entities.secondaryCancers.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.secondaryCancers.fields.ext2'),
  },
  {
    name: 'ext3',
    label: i18n('entities.secondaryCancers.fields.ext3'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.secondaryCancers.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.secondaryCancers.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
