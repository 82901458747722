import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.uiCostsHru.fields.id'),
  },
  {
    name: 'author',
    label: i18n('entities.uiCostsHru.fields.author'),
  },
  {
    name: 'year',
    label: i18n('entities.uiCostsHru.fields.year'),
  },
  {
    name: 'publicationType',
    label: i18n('entities.uiCostsHru.fields.publicationType'),
  },
  {
    name: 'funder',
    label: i18n('entities.uiCostsHru.fields.funder'),
  },
  {
    name: 'country2',
    label: i18n('entities.uiCostsHru.fields.country2'),
  },
  {
    name: 'patientPopulation2',
    label: i18n('entities.uiCostsHru.fields.patientPopulation2'),
  },
  {
    name: 'costs',
    label: i18n('entities.uiCostsHru.fields.costs'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ecru',
    label: i18n('entities.uiCostsHru.fields.ecru'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n('entities.uiCostsHru.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.uiCostsHru.fields.ext2'),
  },
  {
    name: 'ext3',
    label: i18n('entities.uiCostsHru.fields.ext3'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.uiCostsHru.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.uiCostsHru.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
