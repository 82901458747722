
import Permissions from 'src/security/permissions'
import { i18n } from 'src/i18n'
import React from 'react'
import DashboardIcon from '@material-ui/icons/Dashboard'

export default
[
	{
		path: '/summary',
		permissionRequired: true,
		icon: <DashboardIcon />,
		label: 'Summary',
		sectionHead: true,
		subMenus:
		[
			/*
			{
				path: '/ui-epidemiology',
				permissionRequired: Permissions.get('uiEpidemiology', Permissions.READ),
				icon: null,
				label: 'Summary',
			},
			{
				path: '/incidence',
				permissionRequired: Permissions.get('incidence', Permissions.READ),
				icon: null,
				label: i18n('entities.incidence.menu'),
			},
			{
				path: '/survival',
				permissionRequired: Permissions.get('survival', Permissions.READ),
				icon: null,
				label: i18n('entities.survival.menu'),
			},
			{
				path: '/prognostic-characteristics',
				permissionRequired: Permissions.get('prognosticCharacteristics', Permissions.READ),
				icon: null,
				label: 'Prognostic Characteristics',
			},
*/
{
	path: '/summary',
	permissionRequired: Permissions.get('realWorldTreatmentOutcomes', Permissions.READ),
	icon: null,
	label: 'Summary',
},
{
	path: '/summary',
	permissionRequired: Permissions.get('realWorldTreatmentOutcomes', Permissions.READ),
	icon: null,
	label: 'Summary',
},
			
			// {
			// 	path: '/complications',
			// 	permissionRequired: Permissions.get('complications', Permissions.READ),
			// 	icon: null,
			// 	label: 'complications',
			// },

			
			// {
			// 	path: '/slrrepository',
			// 	permissionRequired: Permissions.get('slrrepository', Permissions.READ),
			// 	icon: null,
			// 	label: 'slrrepository',
			// },

			
			// {
			// 	path: '/economic-burden',
			// 	permissionRequired: Permissions.get('economicBurden', Permissions.READ),
			// 	icon: null,
			// 	label: 'Economic Burden',
			// },

			// {
			// 	path: '/patient-burden',
			// 	permissionRequired: Permissions.get('patientBurden', Permissions.READ),
			// 	icon: null,
			// 	label: 'Patient Burden',
			// }
		]
	},
	{
		path: '/slrrepository',
		permissionRequired: true, /* Permissions.get('uiEpidemiology', Permissions.READ), */
		icon: <DashboardIcon />,
		label: 'Repository',
		sectionHead: true,
		

		subMenus:
		[
			
			{
				path: '/slrrepository',
				permissionRequired: Permissions.get('complications', Permissions.READ),
				icon: null,
				label: 'Repository',
			},
			// {
			// 	path: '/real-world-treatment-outcomes',
			// 	permissionRequired: Permissions.get('realWorldTreatmentOutcomes', Permissions.READ),
			// 	icon: null,
			// 	label: i18n('Summary'),
			// },
			// {
			// 	path: '/real-world-outcomes',
			// 	permissionRequired: Permissions.get('realWorldOutcomes', Permissions.READ),
			// 	icon: null,
			// 	label: i18n('Real World Outcomes'),
			// },

			// {
			// 	path: '/real-world-treatment-patterns',
			// 	permissionRequired: Permissions.get('realWorldTreatmentPatterns', Permissions.READ),
			// 	icon: null,
			// 	label: i18n('Real-World Treatment Patterns'),
			// },
			
			
			// {
			// 	path: '/comparative-effectiveness',
			// 	permissionRequired: Permissions.get('comparativeEffectiveness', Permissions.READ),
			// 	icon: null,
			// 	label: i18n('Comparative Effectiveness'),
			// }
		]
	},
	/*{
		path: '/ui-costs-hru',
		permissionRequired: true, /* Permissions.get('uiCostsHru', Permissions.READ), 
		icon: <DashboardIcon />,
		label: 'Economic Data of Treatments',
		sectionHead: true,
		subMenus:
		[
			{
				path: '/ui-costs-hru',
				permissionRequired: Permissions.get('realWorldTreatmentOutcomes', Permissions.READ),
				icon: null,
				label: i18n('Summary'),
			},

			{
				path: '/costs',
				permissionRequired: Permissions.get('costs', Permissions.READ),
				icon: null,
				label: i18n('entities.costs.menu'),
			},

			{
				path: '/resource-utilization',
				permissionRequired: Permissions.get('resourceUtilization', Permissions.READ),
				icon: null,
				label: i18n('entities.resourceUtilization.menu'),
			},

			{
				path: '/models',
				permissionRequired: Permissions.get('models', Permissions.READ),
				icon: null,
				label: i18n('entities.models.menu'),
			}
		]
	},
	{
		path: '/search-strategy/burden-of-cll',
		permissionRequired: true, /* Permissions.get('uiCostsHru', Permissions.READ), 
		icon: <DashboardIcon />,
		label: 'Search Strategy',
		sectionHead: true,
		subMenus:
		[
			{
				path: '/search-strategy/burden-of-cll',
				permissionRequired: Permissions.get('realWorldTreatmentOutcomes', Permissions.READ),
				icon: null,
				label: i18n('Burden of CLL'),
			},
			{
				path: '/search-strategy/real-world-outcomes',
				permissionRequired: Permissions.get('searchStrategyRwo', Permissions.READ),
				icon: null,
				label: i18n('Real-World Outcomes'),
			},
			{
				path: '/search-strategy/economic-data',
				permissionRequired: Permissions.get('searchStrategyRwo', Permissions.READ),
				icon: null,
				label: i18n('Economic Data of Treatments'),
			},
			
		]
	},
	/*
	{
		path: '/ui-references',
		permissionRequired: Permissions.get('uiReferences', Permissions.READ),
		icon: <DashboardIcon />,
		label: 'References',
	}
	*/
].filter(Boolean)
