import list from 'src/modules/realWorldTreatmentOutcomes2/list/realWorldTreatmentOutcomes2ListReducers';
import form from 'src/modules/realWorldTreatmentOutcomes2/form/realWorldTreatmentOutcomes2FormReducers';
import view from 'src/modules/realWorldTreatmentOutcomes2/view/realWorldTreatmentOutcomes2ViewReducers';
import destroy from 'src/modules/realWorldTreatmentOutcomes2/destroy/realWorldTreatmentOutcomes2DestroyReducers';
import importerReducer from 'src/modules/realWorldTreatmentOutcomes2/importer/realWorldTreatmentOutcomes2ImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
