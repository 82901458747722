import Permissions from 'src/security/permissions'

const privateRoutes =
[
	{
		path: '/nullroute',
		loader: () =>
		import('src/view/dashboard/DashboardPage'),
		permissionRequired: null,
		exact: true,
	}
]

const publicRoutes =
[
	/*
	{
		path: '/t/:tenant',
		loader: () => import('src/view/uiEpidemiology/list/UiEpidemiologyListPage'),
		permissionRequired: null,
		exact: true,
	},
	
	{
		path: '/audit-logs',
		loader: () => import('src/view/auditLog/AuditLogPage'),
		permissionRequired: Permissions.get('auditLog', Permissions.READ),
	},
	{
		path: '/incidence',
		loader: () => import('src/view/incidence/list/IncidenceListPage'),
		permissionRequired: Permissions.get('incidence', Permissions.READ),
		exact: true,
	},
	{
		path: '/prognostic-characteristics',
		loader: () => import('src/view/prognosticCharacteristics/list/PrognosticCharacteristicsListPage'),
		permissionRequired: Permissions.get('prognosticCharacteristics', Permissions.READ),
		exact: true,
	},
	{
		path: '/ui-epidemiology',
		loader: () => import('src/view/uiEpidemiology/list/UiEpidemiologyListPage'),
		permissionRequired: Permissions.get('uiEpidemiology', Permissions.READ),
		exact: true,
	},
	{
		path: '/costs',
		loader: () => import('src/view/costs/list/CostsListPage'),
		permissionRequired: Permissions.get('costs', Permissions.READ),
		exact: true,
	},
	{
		path: '/resource-utilization',
		loader: () => import('src/view/resourceUtilization/list/ResourceUtilizationListPage'),
		permissionRequired: Permissions.get('resourceUtilization', Permissions.READ),
		exact: true,
	},
	{
		path: '/models',
		loader: () => import('src/view/models/list/ModelsListPage'),
		permissionRequired: Permissions.get('models', Permissions.READ),
		exact: true,
	},
	{
		path: '/economic',
		loader: () => import('src/view/economic/list/EconomicListPage'),
		permissionRequired: Permissions.get('economic', Permissions.READ),
		exact: true,
	},
	{
		path: '/patient',
		loader: () => import('src/view/patient/list/PatientListPage'),
		permissionRequired: Permissions.get('patient', Permissions.READ),
		exact: true,
	},

	{
		path: '/slrrepository',
		loader: () => import('src/view/secondaryCancers/list/SecondaryCancersListPage'),
		permissionRequired: Permissions.get('slrrepository', Permissions.READ),
		exact: true,
	},
	*/
	
	{
		path: '/slrrepository',
		loader: () => import('src/view/complications/list/ComplicationsListPage'),
		permissionRequired: Permissions.get('/slrrepository', Permissions.READ),
		exact: true,
	},
	{
		path: '/summary',
		loader: () => import( 'src/view/realWorldTreatmentOutcomes/list/RealWorldTreatmentOutcomesListPage'),
		permissionRequired: Permissions.get('/summary', Permissions.READ),
		exact: true,
	},

	{
		path: '/',
		loader: () => import( 'src/view/realWorldTreatmentOutcomes/list/RealWorldTreatmentOutcomesListPage'),
		permissionRequired: Permissions.get('realWorldTreatmentOutcomes', Permissions.READ),
		exact: true,
	},
/*
	{
		path: '/patient-burden',
		loader: () => import('src/view/patientBurden/list/PatientBurdenListPage'),
		permissionRequired: Permissions.get('patientBurden', Permissions.READ),
		exact: true,
	},

	{
		path: '/real-world-treatment-outcomes',
		loader: () => import( 'src/view/realWorldTreatmentOutcomes/list/RealWorldTreatmentOutcomesListPage'),
		permissionRequired: Permissions.get('realWorldTreatmentOutcomes', Permissions.READ),
		exact: true,
	},

	{
		path: '/real-world-outcomes',
		loader: () => import('src/view/realWorldOutcomes/list/RealWorldOutcomesListPage'),
		permissionRequired: Permissions.get('realWorldOutcomes', Permissions.READ),
		exact: true,
	},

	{
		path: '/real-world-treatment-patterns',
		loader: () => import('src/view/realWorldTreatmentPatterns/list/RealWorldTreatmentPatternsListPage'),
		permissionRequired: Permissions.get('realWorldTreatmentPatterns', Permissions.READ),
		exact: true,
	},

	{
		path: '/ui-costs-hru',
		loader: () => import('src/view/uiCostsHru/list/UiCostsHruListPage'),
		permissionRequired: Permissions.get('uiCostsHru', Permissions.READ),
		exact: true,
	},

	{
		path: '/real-world-treatment-outcomes2',
		loader: () => import('src/view/realWorldTreatmentOutcomes2/list/RealWorldTreatmentOutcomes2ListPage'),
		permissionRequired: Permissions.get('realWorldTreatmentOutcomes2', Permissions.READ),
		exact: true,
	},

	{
		path: '/search-strategy/:search_strategy_name',
		loader: () => import('src/view/searchStrategy/list/SearchStrategyListPage'),
		permissionRequired: Permissions.get('searchStrategy', Permissions.READ),
		exact: false,
	},

	{
		path: '/comparative-effectiveness',
		loader: () => import('src/view/comparativeEffectiveness/list/ComparativeEffectivenessListPage'),
		permissionRequired: Permissions.get('comparativeEffectiveness', Permissions.READ),
		exact: true,
	},

	{
		path: '/search-data-economic',
		loader: () => import('src/view/searchDataEconomic/list/SearchDataEconomicListPage'),
		permissionRequired: Permissions.get('searchDataEconomic', Permissions.READ),
		exact: true,
	},

	{
		path: '/ui-references',
		loader: () => import('src/view/uiReferences/list/UiReferencesListPage'),
		permissionRequired: Permissions.get('uiReferences', Permissions.READ),
		exact: true,
	},

	{
		path: '/survival',
		loader: () => import('src/view/survival/list/SurvivalListPage'),
		permissionRequired: Permissions.get('survival', Permissions.READ),
		exact: true,
	}
	*/
	
].filter(Boolean)

const emptyTenantRoutes = [].filter(Boolean)

const simpleRoutes =
[
	{
		path: '/403',
		loader: () => import('src/view/shared/errors/Error403Page')
	},
	{
		path: '/500',
		loader: () => import('src/view/shared/errors/Error500Page')
	},
	{
		path: '**',
		loader: () => import('src/view/shared/errors/Error404Page')
	},
].filter(Boolean)

export default { emptyTenantRoutes, privateRoutes, publicRoutes, simpleRoutes }

