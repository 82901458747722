import list from 'src/modules/models/list/modelsListReducers';
import form from 'src/modules/models/form/modelsFormReducers';
import view from 'src/modules/models/view/modelsViewReducers';
import destroy from 'src/modules/models/destroy/modelsDestroyReducers';
import importerReducer from 'src/modules/models/importer/modelsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
