import list from 'src/modules/realWorldTreatmentPatterns/list/realWorldTreatmentPatternsListReducers';
import form from 'src/modules/realWorldTreatmentPatterns/form/realWorldTreatmentPatternsFormReducers';
import view from 'src/modules/realWorldTreatmentPatterns/view/realWorldTreatmentPatternsViewReducers';
import destroy from 'src/modules/realWorldTreatmentPatterns/destroy/realWorldTreatmentPatternsDestroyReducers';
import importerReducer from 'src/modules/realWorldTreatmentPatterns/importer/realWorldTreatmentPatternsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
