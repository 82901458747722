import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.prognosticCharacteristics.fields.id'),
  },
  {
    name: 'regionSel',
    label: i18n('entities.prognosticCharacteristics.fields.regionSel'),
  },
  {
    name: 'yearSel',
    label: i18n('entities.prognosticCharacteristics.fields.yearSel'),
  },
  {
    name: 'patientPopSel',
    label: i18n('entities.prognosticCharacteristics.fields.patientPopSel'),
  },
  {
    name: 'dataSource',
    label: i18n('entities.prognosticCharacteristics.fields.dataSource'),
  },
  {
    name: 'factorSel',
    label: i18n('entities.prognosticCharacteristics.fields.factorSel'),
  },
  {
    name: 'study',
    label: i18n('entities.prognosticCharacteristics.fields.study'),
  },
  {
    name: 'year',
    label: i18n('entities.prognosticCharacteristics.fields.year'),
  },
  {
    name: 'studyType',
    label: i18n('entities.prognosticCharacteristics.fields.studyType'),
  },
  {
    name: 'country',
    label: i18n('entities.prognosticCharacteristics.fields.country'),
  },
  {
    name: 'data',
    label: i18n('entities.prognosticCharacteristics.fields.data'),
  },
  {
    name: 'timePeriod',
    label: i18n('entities.prognosticCharacteristics.fields.timePeriod'),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.prognosticCharacteristics.fields.patientPopulation'),
  },
  {
    name: 'prognosticFactor',
    label: i18n('entities.prognosticCharacteristics.fields.prognosticFactor'),
  },
  {
    name: 'endpoints',
    label: i18n('entities.prognosticCharacteristics.fields.endpoints'),
  },
  {
    name: 'results',
    label: i18n('entities.prognosticCharacteristics.fields.results'),
  },
  {
    name: 'count',
    label: i18n('entities.prognosticCharacteristics.fields.count'),
  },
  {
    name: 'rowIsVisible',
    label: i18n('entities.prognosticCharacteristics.fields.rowIsVisible'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'color',
    label: i18n('entities.prognosticCharacteristics.fields.color'),
  },
  {
    name: 'africa',
    label: i18n('entities.prognosticCharacteristics.fields.africa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asiaPacific',
    label: i18n('entities.prognosticCharacteristics.fields.asiaPacific'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'australia',
    label: i18n('entities.prognosticCharacteristics.fields.australia'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'europe',
    label: i18n('entities.prognosticCharacteristics.fields.europe'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'latinAmerica',
    label: i18n('entities.prognosticCharacteristics.fields.latinAmerica'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'middleEast',
    label: i18n('entities.prognosticCharacteristics.fields.middleEast'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'usa',
    label: i18n('entities.prognosticCharacteristics.fields.usa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'canada',
    label: i18n('entities.prognosticCharacteristics.fields.canada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result',
    label: i18n('entities.prognosticCharacteristics.fields.result'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'value',
    label: i18n('entities.prognosticCharacteristics.fields.value'),
  },
  {
    name: 'cll',
    label: i18n('entities.prognosticCharacteristics.fields.cll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rrCll',
    label: i18n('entities.prognosticCharacteristics.fields.rrCll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result2',
    label: i18n('entities.prognosticCharacteristics.fields.result2'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'claimsDatabase',
    label: i18n('entities.prognosticCharacteristics.fields.claimsDatabase'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'eap',
    label: i18n('entities.prognosticCharacteristics.fields.eap'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'literature',
    label: i18n('entities.prognosticCharacteristics.fields.literature'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'medicalCenter',
    label: i18n('entities.prognosticCharacteristics.fields.medicalCenter'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'registry',
    label: i18n('entities.prognosticCharacteristics.fields.registry'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result3',
    label: i18n('entities.prognosticCharacteristics.fields.result3'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'del17p',
    label: i18n('entities.prognosticCharacteristics.fields.del17p'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'del13q',
    label: i18n('entities.prognosticCharacteristics.fields.del13q'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'tp53',
    label: i18n('entities.prognosticCharacteristics.fields.tp53'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result4',
    label: i18n('entities.prognosticCharacteristics.fields.result4'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n('entities.prognosticCharacteristics.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.prognosticCharacteristics.fields.ext2'),
  },
  {
    name: 'ext3',
    label: i18n('entities.prognosticCharacteristics.fields.ext3'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.prognosticCharacteristics.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.prognosticCharacteristics.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
