import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/realWorldOutcomes/importer/realWorldOutcomesImporterSelectors';
import RealWorldOutcomesService from 'src/modules/realWorldOutcomes/realWorldOutcomesService';
import fields from 'src/modules/realWorldOutcomes/importer/realWorldOutcomesImporterFields';
import { i18n } from 'src/i18n';

const realWorldOutcomesImporterActions = importerActions(
  'REALWORLDOUTCOMES_IMPORTER',
  selectors,
  RealWorldOutcomesService.import,
  fields,
  i18n('entities.realWorldOutcomes.importer.fileName'),
);

export default realWorldOutcomesImporterActions;