import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.patient.fields.id'),
  },
  {
    name: 'study',
    label: i18n('entities.patient.fields.study'),
  },
  {
    name: 'sponsor',
    label: i18n('entities.patient.fields.sponsor'),
  },
  {
    name: 'publicationType',
    label: i18n('entities.patient.fields.publicationType'),
  },
  {
    name: 'country',
    label: i18n('entities.patient.fields.country'),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.patient.fields.patientPopulation'),
  },
  {
    name: 'measures',
    label: i18n('entities.patient.fields.measures'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.patient.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.patient.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
