import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'terms',
    label: i18n('entities.searchDataEconomic.fields.terms'),
    schema: schemas.string(
      i18n('entities.searchDataEconomic.fields.terms'),
      {},
    ),
  },
  {
    name: 'medline',
    label: i18n('entities.searchDataEconomic.fields.medline'),
    schema: schemas.string(
      i18n('entities.searchDataEconomic.fields.medline'),
      {},
    ),
  },
  {
    name: 'embase',
    label: i18n('entities.searchDataEconomic.fields.embase'),
    schema: schemas.string(
      i18n('entities.searchDataEconomic.fields.embase'),
      {},
    ),
  },
];