import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import resourceUtilizationEnumerators from 'src/modules/resourceUtilization/resourceUtilizationEnumerators';

export default [
  {
    name: 'area',
    label: i18n('entities.resourceUtilization.fields.area'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.area'),
      {},
    ),
  },
  {
    name: 'subarea',
    label: i18n('entities.resourceUtilization.fields.subarea'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.subarea'),
      {},
    ),
  },
  {
    name: 'regionSel',
    label: i18n('entities.resourceUtilization.fields.regionSel'),
    schema: schemas.enumerator(
      i18n('entities.resourceUtilization.fields.regionSel'),
      {
        "options": resourceUtilizationEnumerators.regionSel
      },
    ),
  },
  {
    name: 'yearSel',
    label: i18n('entities.resourceUtilization.fields.yearSel'),
    schema: schemas.enumerator(
      i18n('entities.resourceUtilization.fields.yearSel'),
      {
        "options": resourceUtilizationEnumerators.yearSel
      },
    ),
  },
  {
    name: 'patientPopSel',
    label: i18n('entities.resourceUtilization.fields.patientPopSel'),
    schema: schemas.enumerator(
      i18n('entities.resourceUtilization.fields.patientPopSel'),
      {
        "options": resourceUtilizationEnumerators.patientPopSel
      },
    ),
  },
  {
    name: 'dataSourceSel',
    label: i18n('entities.resourceUtilization.fields.dataSourceSel'),
    schema: schemas.enumerator(
      i18n('entities.resourceUtilization.fields.dataSourceSel'),
      {
        "options": resourceUtilizationEnumerators.dataSourceSel
      },
    ),
  },
  {
    name: 'outcomeSel',
    label: i18n('entities.resourceUtilization.fields.outcomeSel'),
    schema: schemas.enumerator(
      i18n('entities.resourceUtilization.fields.outcomeSel'),
      {
        "options": resourceUtilizationEnumerators.outcomeSel
      },
    ),
  },
  {
    name: 'drugSel',
    label: i18n('entities.resourceUtilization.fields.drugSel'),
    schema: schemas.enumerator(
      i18n('entities.resourceUtilization.fields.drugSel'),
      {
        "options": resourceUtilizationEnumerators.drugSel
      },
    ),
  },
  {
    name: 'author',
    label: i18n('entities.resourceUtilization.fields.author'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.author'),
      {},
    ),
  },
  {
    name: 'year',
    label: i18n('entities.resourceUtilization.fields.year'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.year'),
      {},
    ),
  },
  {
    name: 'publicationType',
    label: i18n('entities.resourceUtilization.fields.publicationType'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.publicationType'),
      {},
    ),
  },
  {
    name: 'studyType',
    label: i18n('entities.resourceUtilization.fields.studyType'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.studyType'),
      {},
    ),
  },
  {
    name: 'funder',
    label: i18n('entities.resourceUtilization.fields.funder'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.funder'),
      {},
    ),
  },
  {
    name: 'country',
    label: i18n('entities.resourceUtilization.fields.country'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.country'),
      {},
    ),
  },
  {
    name: 'data',
    label: i18n('entities.resourceUtilization.fields.data'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.data'),
      {},
    ),
  },
  {
    name: 'timePeriod',
    label: i18n('entities.resourceUtilization.fields.timePeriod'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.timePeriod'),
      {},
    ),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.resourceUtilization.fields.patientPopulation'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.patientPopulation'),
      {},
    ),
  },
  {
    name: 'interventions',
    label: i18n('entities.resourceUtilization.fields.interventions'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.interventions'),
      {},
    ),
  },
  {
    name: 'outcomes',
    label: i18n('entities.resourceUtilization.fields.outcomes'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.outcomes'),
      {},
    ),
  },
  {
    name: 'results',
    label: i18n('entities.resourceUtilization.fields.results'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.results'),
      {},
    ),
  },
  {
    name: 'count',
    label: i18n('entities.resourceUtilization.fields.count'),
    schema: schemas.integer(
      i18n('entities.resourceUtilization.fields.count'),
      {},
    ),
  },
  {
    name: 'rowIsVisible',
    label: i18n('entities.resourceUtilization.fields.rowIsVisible'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.rowIsVisible'),
      {},
    ),
  },
  {
    name: 'rowColor',
    label: i18n('entities.resourceUtilization.fields.rowColor'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.rowColor'),
      {},
    ),
  },
  {
    name: 'author2',
    label: i18n('entities.resourceUtilization.fields.author2'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.author2'),
      {},
    ),
  },
  {
    name: 'africa',
    label: i18n('entities.resourceUtilization.fields.africa'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.africa'),
      {},
    ),
  },
  {
    name: 'asiaPacific',
    label: i18n('entities.resourceUtilization.fields.asiaPacific'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.asiaPacific'),
      {},
    ),
  },
  {
    name: 'australia',
    label: i18n('entities.resourceUtilization.fields.australia'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.australia'),
      {},
    ),
  },
  {
    name: 'europe',
    label: i18n('entities.resourceUtilization.fields.europe'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.europe'),
      {},
    ),
  },
  {
    name: 'latinAmerica',
    label: i18n('entities.resourceUtilization.fields.latinAmerica'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.latinAmerica'),
      {},
    ),
  },
  {
    name: 'middleEast',
    label: i18n('entities.resourceUtilization.fields.middleEast'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.middleEast'),
      {},
    ),
  },
  {
    name: 'usa',
    label: i18n('entities.resourceUtilization.fields.usa'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.usa'),
      {},
    ),
  },
  {
    name: 'canada',
    label: i18n('entities.resourceUtilization.fields.canada'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.canada'),
      {},
    ),
  },
  {
    name: 'result',
    label: i18n('entities.resourceUtilization.fields.result'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.result'),
      {},
    ),
  },
  {
    name: 'cll',
    label: i18n('entities.resourceUtilization.fields.cll'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.cll'),
      {},
    ),
  },
  {
    name: 'rrCll',
    label: i18n('entities.resourceUtilization.fields.rrCll'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.rrCll'),
      {},
    ),
  },
  {
    name: 'result2',
    label: i18n('entities.resourceUtilization.fields.result2'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.result2'),
      {},
    ),
  },
  {
    name: 'claimsDatabase',
    label: i18n('entities.resourceUtilization.fields.claimsDatabase'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.claimsDatabase'),
      {},
    ),
  },
  {
    name: 'eap',
    label: i18n('entities.resourceUtilization.fields.eap'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.eap'),
      {},
    ),
  },
  {
    name: 'literature',
    label: i18n('entities.resourceUtilization.fields.literature'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.literature'),
      {},
    ),
  },
  {
    name: 'medicalCenters',
    label: i18n('entities.resourceUtilization.fields.medicalCenters'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.medicalCenters'),
      {},
    ),
  },
  {
    name: 'registry',
    label: i18n('entities.resourceUtilization.fields.registry'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.registry'),
      {},
    ),
  },
  {
    name: 'result3',
    label: i18n('entities.resourceUtilization.fields.result3'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.result3'),
      {},
    ),
  },
  {
    name: 'total',
    label: i18n('entities.resourceUtilization.fields.total'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.total'),
      {},
    ),
  },
  {
    name: 'inpatient',
    label: i18n('entities.resourceUtilization.fields.inpatient'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.inpatient'),
      {},
    ),
  },
  {
    name: 'result4',
    label: i18n('entities.resourceUtilization.fields.result4'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.result4'),
      {},
    ),
  },
  {
    name: 'acalabrutinib',
    label: i18n('entities.resourceUtilization.fields.acalabrutinib'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.acalabrutinib'),
      {},
    ),
  },
  {
    name: 'bendamustine',
    label: i18n('entities.resourceUtilization.fields.bendamustine'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.bendamustine'),
      {},
    ),
  },
  {
    name: 'duvelisib',
    label: i18n('entities.resourceUtilization.fields.duvelisib'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.duvelisib'),
      {},
    ),
  },
  {
    name: 'ibrutinib',
    label: i18n('entities.resourceUtilization.fields.ibrutinib'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.ibrutinib'),
      {},
    ),
  },
  {
    name: 'idelalisib',
    label: i18n('entities.resourceUtilization.fields.idelalisib'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.idelalisib'),
      {},
    ),
  },
  {
    name: 'lenalidomide',
    label: i18n('entities.resourceUtilization.fields.lenalidomide'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.lenalidomide'),
      {},
    ),
  },
  {
    name: 'obinutuzumab',
    label: i18n('entities.resourceUtilization.fields.obinutuzumab'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.obinutuzumab'),
      {},
    ),
  },
  {
    name: 'ofatumumab',
    label: i18n('entities.resourceUtilization.fields.ofatumumab'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.ofatumumab'),
      {},
    ),
  },
  {
    name: 'venetoclax',
    label: i18n('entities.resourceUtilization.fields.venetoclax'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.venetoclax'),
      {},
    ),
  },
  {
    name: 'result5',
    label: i18n('entities.resourceUtilization.fields.result5'),
    schema: schemas.boolean(
      i18n('entities.resourceUtilization.fields.result5'),
      {},
    ),
  },
  {
    name: 'ext1',
    label: i18n('entities.resourceUtilization.fields.ext1'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.ext1'),
      {},
    ),
  },
  {
    name: 'ext2',
    label: i18n('entities.resourceUtilization.fields.ext2'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.ext2'),
      {},
    ),
  },
  {
    name: 'ext3',
    label: i18n('entities.resourceUtilization.fields.ext3'),
    schema: schemas.string(
      i18n('entities.resourceUtilization.fields.ext3'),
      {},
    ),
  },
];