import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import patientBurdenEnumerators from 'src/modules/patientBurden/patientBurdenEnumerators';

export default [
  {
    name: 'regionsSel',
    label: i18n('entities.patientBurden.fields.regionsSel'),
    schema: schemas.enumerator(
      i18n('entities.patientBurden.fields.regionsSel'),
      {
        options: patientBurdenEnumerators.regionsSel,
      },
    ),
  },
  {
    name: 'yearSel',
    label: i18n('entities.patientBurden.fields.yearSel'),
    schema: schemas.enumerator(
      i18n('entities.patientBurden.fields.yearSel'),
      {
        options: patientBurdenEnumerators.yearSel,
      },
    ),
  },
  {
    name: 'patientPopSel',
    label: i18n(
      'entities.patientBurden.fields.patientPopSel',
    ),
    schema: schemas.enumerator(
      i18n('entities.patientBurden.fields.patientPopSel'),
      {
        options: patientBurdenEnumerators.patientPopSel,
      },
    ),
  },
  {
    name: 'study',
    label: i18n('entities.patientBurden.fields.study'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.study'),
      {},
    ),
  },
  {
    name: 'year',
    label: i18n('entities.patientBurden.fields.year'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.year'),
      {},
    ),
  },
  {
    name: 'studyType',
    label: i18n('entities.patientBurden.fields.studyType'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.studyType'),
      {},
    ),
  },
  {
    name: 'country',
    label: i18n('entities.patientBurden.fields.country'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.country'),
      {},
    ),
  },
  {
    name: 'data',
    label: i18n('entities.patientBurden.fields.data'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.data'),
      {},
    ),
  },
  {
    name: 'timePeriod',
    label: i18n('entities.patientBurden.fields.timePeriod'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.timePeriod'),
      {},
    ),
  },
  {
    name: 'patientPopulation',
    label: i18n(
      'entities.patientBurden.fields.patientPopulation',
    ),
    schema: schemas.string(
      i18n(
        'entities.patientBurden.fields.patientPopulation',
      ),
      {},
    ),
  },
  {
    name: 'measures',
    label: i18n('entities.patientBurden.fields.measures'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.measures'),
      {},
    ),
  },
  {
    name: 'results',
    label: i18n('entities.patientBurden.fields.results'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.results'),
      {},
    ),
  },
  {
    name: 'count',
    label: i18n('entities.patientBurden.fields.count'),
    schema: schemas.integer(
      i18n('entities.patientBurden.fields.count'),
      {},
    ),
  },
  {
    name: 'rowIsVisible',
    label: i18n(
      'entities.patientBurden.fields.rowIsVisible',
    ),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.rowIsVisible'),
      {},
    ),
  },
  {
    name: 'rowColor',
    label: i18n('entities.patientBurden.fields.rowColor'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.rowColor'),
      {},
    ),
  },
  {
    name: 'africa',
    label: i18n('entities.patientBurden.fields.africa'),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.africa'),
      {},
    ),
  },
  {
    name: 'asiaPacific',
    label: i18n(
      'entities.patientBurden.fields.asiaPacific',
    ),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.asiaPacific'),
      {},
    ),
  },
  {
    name: 'australia',
    label: i18n('entities.patientBurden.fields.australia'),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.australia'),
      {},
    ),
  },
  {
    name: 'europe',
    label: i18n('entities.patientBurden.fields.europe'),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.europe'),
      {},
    ),
  },
  {
    name: 'latinAmerica',
    label: i18n(
      'entities.patientBurden.fields.latinAmerica',
    ),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.latinAmerica'),
      {},
    ),
  },
  {
    name: 'middleEast',
    label: i18n('entities.patientBurden.fields.middleEast'),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.middleEast'),
      {},
    ),
  },
  {
    name: 'usa',
    label: i18n('entities.patientBurden.fields.usa'),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.usa'),
      {},
    ),
  },
  {
    name: 'canada',
    label: i18n('entities.patientBurden.fields.canada'),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.canada'),
      {},
    ),
  },
  {
    name: 'result',
    label: i18n('entities.patientBurden.fields.result'),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.result'),
      {},
    ),
  },
  {
    name: 'year',
    label: i18n('entities.patientBurden.fields.year'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.year'),
      {},
    ),
  },
  {
    name: 'result2',
    label: i18n('entities.patientBurden.fields.result2'),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.result2'),
      {},
    ),
  },
  {
    name: 'cll',
    label: i18n('entities.patientBurden.fields.cll'),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.cll'),
      {},
    ),
  },
  {
    name: 'rrCll',
    label: i18n('entities.patientBurden.fields.rrCll'),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.rrCll'),
      {},
    ),
  },
  {
    name: 'result3',
    label: i18n('entities.patientBurden.fields.result3'),
    schema: schemas.boolean(
      i18n('entities.patientBurden.fields.result3'),
      {},
    ),
  },
  {
    name: 'ext1',
    label: i18n('entities.patientBurden.fields.ext1'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.ext1'),
      {},
    ),
  },
  {
    name: 'ext2',
    label: i18n('entities.patientBurden.fields.ext2'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.ext2'),
      {},
    ),
  },
  {
    name: 'ext3',
    label: i18n('entities.patientBurden.fields.ext3'),
    schema: schemas.string(
      i18n('entities.patientBurden.fields.ext3'),
      {},
    ),
  },
];
