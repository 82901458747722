import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.uiReferences.fields.id'),
  },
  {
    name: 'reference',
    label: i18n('entities.uiReferences.fields.reference'),
  },
  {
    name: 'ext1',
    label: i18n('entities.uiReferences.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.uiReferences.fields.ext2'),
  },
  {
    name: 'ext3',
    label: i18n('entities.uiReferences.fields.ext3'),
  },
  {
    name: 'ext4',
    label: i18n('entities.uiReferences.fields.ext4'),
  },
  {
    name: 'ext5',
    label: i18n('entities.uiReferences.fields.ext5'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.uiReferences.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.uiReferences.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
