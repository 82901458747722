import list from 'src/modules/secondaryCancers/list/secondaryCancersListReducers';
import form from 'src/modules/secondaryCancers/form/secondaryCancersFormReducers';
import view from 'src/modules/secondaryCancers/view/secondaryCancersViewReducers';
import destroy from 'src/modules/secondaryCancers/destroy/secondaryCancersDestroyReducers';
import importerReducer from 'src/modules/secondaryCancers/importer/secondaryCancersImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
