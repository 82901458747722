import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.models.fields.id'),
  },
  {
    name: 'table',
    label: i18n('entities.models.fields.table'),
  },
  {
    name: 'area',
    label: i18n('entities.models.fields.area'),
  },
  {
    name: 'regionSel',
    label: i18n('entities.models.fields.regionSel'),
  },
  {
    name: 'yearSel',
    label: i18n('entities.models.fields.yearSel'),
  },
  {
    name: 'patientPopSel',
    label: i18n('entities.models.fields.patientPopSel'),
  },
  {
    name: 'drugSel',
    label: i18n('entities.models.fields.drugSel'),
  },
  {
    name: 'modelTypeSel',
    label: i18n('entities.models.fields.modelTypeSel'),
  },
  {
    name: 'author',
    label: i18n('entities.models.fields.author'),
  },
  {
    name: 'year',
    label: i18n('entities.models.fields.year'),
  },
  {
    name: 'publicationType',
    label: i18n('entities.models.fields.publicationType'),
  },
  {
    name: 'funder',
    label: i18n('entities.models.fields.funder'),
  },
  {
    name: 'country',
    label: i18n('entities.models.fields.country'),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.models.fields.patientPopulation'),
  },
  {
    name: 'interventions',
    label: i18n('entities.models.fields.interventions'),
  },
  {
    name: 'data',
    label: i18n('entities.models.fields.data'),
  },
  {
    name: 'outcomes',
    label: i18n('entities.models.fields.outcomes'),
  },
  {
    name: 'results',
    label: i18n('entities.models.fields.results'),
  },
  {
    name: 'count',
    label: i18n('entities.models.fields.count'),
  },
  {
    name: 'rowIsVisible',
    label: i18n('entities.models.fields.rowIsVisible'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rowColor',
    label: i18n('entities.models.fields.rowColor'),
  },
  {
    name: 'author2',
    label: i18n('entities.models.fields.author2'),
  },
  {
    name: 'africa',
    label: i18n('entities.models.fields.africa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asiaPacific',
    label: i18n('entities.models.fields.asiaPacific'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'australia',
    label: i18n('entities.models.fields.australia'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'europe',
    label: i18n('entities.models.fields.europe'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'latinAmerica',
    label: i18n('entities.models.fields.latinAmerica'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'middleEast',
    label: i18n('entities.models.fields.middleEast'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'usa',
    label: i18n('entities.models.fields.usa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'canada',
    label: i18n('entities.models.fields.canada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result',
    label: i18n('entities.models.fields.result'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'valueYear',
    label: i18n('entities.models.fields.valueYear'),
  },
  {
    name: 'result2',
    label: i18n('entities.models.fields.result2'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'cll',
    label: i18n('entities.models.fields.cll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rrCli',
    label: i18n('entities.models.fields.rrCli'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result3',
    label: i18n('entities.models.fields.result3'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ceaModel',
    label: i18n('entities.models.fields.ceaModel'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'bimModel',
    label: i18n('entities.models.fields.bimModel'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result4',
    label: i18n('entities.models.fields.result4'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'acalabrutinib',
    label: i18n('entities.models.fields.acalabrutinib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'bendamustine',
    label: i18n('entities.models.fields.bendamustine'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'duvelisib',
    label: i18n('entities.models.fields.duvelisib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ibrutinib',
    label: i18n('entities.models.fields.ibrutinib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'idelalisib',
    label: i18n('entities.models.fields.idelalisib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'lenalidomide',
    label: i18n('entities.models.fields.lenalidomide'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'obinutuzumab',
    label: i18n('entities.models.fields.obinutuzumab'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ofatumumab',
    label: i18n('entities.models.fields.ofatumumab'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'venetoclax',
    label: i18n('entities.models.fields.venetoclax'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result5',
    label: i18n('entities.models.fields.result5'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n('entities.models.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.models.fields.ext2'),
  },
  {
    name: 'ext3',
    label: i18n('entities.models.fields.ext3'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.models.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.models.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
