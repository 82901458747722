import listActions from 'src/modules/realWorldTreatmentOutcomes/list/realWorldTreatmentOutcomesListActions';
import RealWorldTreatmentOutcomesService from 'src/modules/realWorldTreatmentOutcomes/realWorldTreatmentOutcomesService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'REALWORLDTREATMENTOUTCOMES_DESTROY';

const realWorldTreatmentOutcomesDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  // doDestroy: (id) => async (dispatch) => {
  //   try {
  //     dispatch({
  //       type: realWorldTreatmentOutcomesDestroyActions.DESTROY_STARTED,
  //     });

  //     await RealWorldTreatmentOutcomesService.destroyAll([id]);

  //     dispatch({
  //       type: realWorldTreatmentOutcomesDestroyActions.DESTROY_SUCCESS,
  //     });

  //     Message.success(
  //       i18n('entities.realWorldTreatmentOutcomes.destroy.success'),
  //     );

  //     dispatch(listActions.doFetchCurrentFilter());

  //     getHistory().push('/real-world-treatment-outcomes');
  //   } catch (error) {
  //     Errors.handle(error);

  //     dispatch(listActions.doFetchCurrentFilter());

  //     dispatch({
  //       type: realWorldTreatmentOutcomesDestroyActions.DESTROY_ERROR,
  //     });
  //   }
  // },

  // doDestroyAll: (ids) => async (dispatch) => {
  //   try {
  //     dispatch({
  //       type: realWorldTreatmentOutcomesDestroyActions.DESTROY_ALL_STARTED,
  //     });

  //     await RealWorldTreatmentOutcomesService.destroyAll(ids);

  //     dispatch({
  //       type: realWorldTreatmentOutcomesDestroyActions.DESTROY_ALL_SUCCESS,
  //     });

  //     if (listActions) {
  //       dispatch(listActions.doClearAllSelected());
  //       dispatch(listActions.doFetchCurrentFilter());
  //     }

  //     Message.success(
  //       i18n('entities.realWorldTreatmentOutcomes.destroyAll.success'),
  //     );

  //     getHistory().push('/real-world-treatment-outcomes');
  //   } catch (error) {
  //     Errors.handle(error);

  //     dispatch(listActions.doFetchCurrentFilter());

  //     dispatch({
  //       type: realWorldTreatmentOutcomesDestroyActions.DESTROY_ALL_ERROR,
  //     });
  //   }
  // },
};

export default realWorldTreatmentOutcomesDestroyActions;
