import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import secondaryCancersEnumerators from 'src/modules/secondaryCancers/secondaryCancersEnumerators';

export default [
  {
    name: 'METHODOLOGY',
    label: i18n(
      'entities.secondaryCancers.fields.METHODOLOGY',
    ),
    schema: schemas.enumerator(
      i18n('entities.secondaryCancers.fields.METHODOLOGY'),
      {
        options: secondaryCancersEnumerators.METHODOLOGY,
      },
    ),
  },
  {
    name: 'yearSel',
    label: i18n('entities.secondaryCancers.fields.yearSel'),
    schema: schemas.enumerator(
      i18n('entities.secondaryCancers.fields.yearSel'),
      {
        options: secondaryCancersEnumerators.yearSel,
      },
    ),
  },
  {
    name: 'OUTCOMES',
    label: i18n(
      'entities.secondaryCancers.fields.OUTCOMES',
    ),
    schema: schemas.enumerator(
      i18n(
        'entities.secondaryCancers.fields.OUTCOMES',
      ),
      {
        options: secondaryCancersEnumerators.OUTCOMES,
      },
    ),
  },
  {
    name: 'SEARCHDATABASE',
    label: i18n(
      'entities.secondaryCancers.fields.SEARCHDATABASE',
    ),
    schema: schemas.enumerator(
      i18n(
        'entities.secondaryCancers.fields.SEARCHDATABASE',
      ),
      {
        options: secondaryCancersEnumerators.SEARCHDATABASE,
      },
    ),
  },
  {
    name: 'STUDYSETTING',
    label: i18n(
      'entities.secondaryCancers.fields.STUDYSETTING',
    ),
    schema: schemas.enumerator(
      i18n(
        'entities.secondaryCancers.fields.STUDYSETTING',
      ),
      {
        options: secondaryCancersEnumerators.STUDYSETTING,
      },
    ),
  },
  {
    name: 'dataSourceSel',
    label: i18n(
      'entities.secondaryCancers.fields.dataSourceSel',
    ),
    schema: schemas.enumerator(
      i18n(
        'entities.secondaryCancers.fields.dataSourceSel',
      ),
      {
        options: secondaryCancersEnumerators.dataSourceSel,
      },
    ),
  },
  {
    name: 'author',
    label: i18n('entities.secondaryCancers.fields.author'),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.author'),
      {},
    ),
  },
  {
    name: 'Year',
    label: i18n('entities.secondaryCancers.fields.Year'),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.Year'),
      {},
    ),
  },
  {
    name: 'studysetting',
    label: i18n(
      'entities.secondaryCancers.fields.studysetting',
    ),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.studysetting'),
      {},
    ),
  },
  {
    name: 'DataSource',
    label: i18n('entities.secondaryCancers.fields.DataSource'),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.DataSource'),
      {},
    ),
  },
  {
    name: 'StudyTimeline',
    label: i18n('entities.secondaryCancers.fields.StudyTimeline'),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.StudyTimeline'),
      {},
    ),
  },
  {
    name: 'Studysamplesize',
    label: i18n(
      'entities.secondaryCancers.fields.Studysamplesize',
    ),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.Studysamplesize'),
      {},
    ),
  },
  {
    name: 'patientPopulation',
    label: i18n(
      'entities.secondaryCancers.fields.patientPopulation',
    ),
    schema: schemas.string(
      i18n(
        'entities.secondaryCancers.fields.patientPopulation',
      ),
      {},
    ),
  },
  {
    name: 'secondaryCancer',
    label: i18n(
      'entities.secondaryCancers.fields.secondaryCancer',
    ),
    schema: schemas.string(
      i18n(
        'entities.secondaryCancers.fields.secondaryCancer',
      ),
      {},
    ),
  },
  {
    name: 'endpoints',
    label: i18n(
      'entities.secondaryCancers.fields.endpoints',
    ),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.endpoints'),
      {},
    ),
  },
  {
    name: 'results',
    label: i18n('entities.secondaryCancers.fields.results'),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.results'),
      {},
    ),
  },
  {
    name: 'count',
    label: i18n('entities.secondaryCancers.fields.count'),
    schema: schemas.integer(
      i18n('entities.secondaryCancers.fields.count'),
      {},
    ),
  },
  {
    name: 'rowIsVisible',
    label: i18n(
      'entities.secondaryCancers.fields.rowIsVisible',
    ),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.rowIsVisible'),
      {},
    ),
  },
  {
    name: 'rowColor',
    label: i18n(
      'entities.secondaryCancers.fields.rowColor',
    ),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.rowColor'),
      {},
    ),
  },
  {
    name: 'africa',
    label: i18n('entities.secondaryCancers.fields.africa'),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.africa'),
      {},
    ),
  },
  {
    name: 'asiaPacific',
    label: i18n(
      'entities.secondaryCancers.fields.asiaPacific',
    ),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.asiaPacific'),
      {},
    ),
  },
  {
    name: 'australia',
    label: i18n(
      'entities.secondaryCancers.fields.australia',
    ),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.australia'),
      {},
    ),
  },
  {
    name: 'europe',
    label: i18n('entities.secondaryCancers.fields.europe'),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.europe'),
      {},
    ),
  },
  {
    name: 'latinAmerica',
    label: i18n(
      'entities.secondaryCancers.fields.latinAmerica',
    ),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.latinAmerica'),
      {},
    ),
  },
  {
    name: 'middleEast',
    label: i18n(
      'entities.secondaryCancers.fields.middleEast',
    ),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.middleEast'),
      {},
    ),
  },
  {
    name: 'usa',
    label: i18n('entities.secondaryCancers.fields.usa'),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.usa'),
      {},
    ),
  },
  {
    name: 'canada',
    label: i18n('entities.secondaryCancers.fields.canada'),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.canada'),
      {},
    ),
  },
  {
    name: 'result',
    label: i18n('entities.secondaryCancers.fields.result'),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.result'),
      {},
    ),
  },
  {
    name: 'value',
    label: i18n('entities.secondaryCancers.fields.value'),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.value'),
      {},
    ),
  },
  {
    name: 'result2',
    label: i18n('entities.secondaryCancers.fields.result2'),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.result2'),
      {},
    ),
  },
  {
    name: 'cll',
    label: i18n('entities.secondaryCancers.fields.cll'),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.cll'),
      {},
    ),
  },
  {
    name: 'rrCll',
    label: i18n('entities.secondaryCancers.fields.rrCll'),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.rrCll'),
      {},
    ),
  },
  {
    name: 'result3',
    label: i18n('entities.secondaryCancers.fields.result3'),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.result3'),
      {},
    ),
  },
  {
    name: 'claimsDatabase',
    label: i18n(
      'entities.secondaryCancers.fields.claimsDatabase',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.secondaryCancers.fields.claimsDatabase',
      ),
      {},
    ),
  },
  {
    name: 'eap',
    label: i18n('entities.secondaryCancers.fields.eap'),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.eap'),
      {},
    ),
  },
  {
    name: 'literature',
    label: i18n(
      'entities.secondaryCancers.fields.literature',
    ),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.literature'),
      {},
    ),
  },
  {
    name: 'medicalCenters',
    label: i18n(
      'entities.secondaryCancers.fields.medicalCenters',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.secondaryCancers.fields.medicalCenters',
      ),
      {},
    ),
  },
  {
    name: 'registry',
    label: i18n(
      'entities.secondaryCancers.fields.registry',
    ),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.registry'),
      {},
    ),
  },
  {
    name: 'result4',
    label: i18n('entities.secondaryCancers.fields.result4'),
    schema: schemas.boolean(
      i18n('entities.secondaryCancers.fields.result4'),
      {},
    ),
  },
  {
    name: 'ext1',
    label: i18n('entities.secondaryCancers.fields.ext1'),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.ext1'),
      {},
    ),
  },
  {
    name: 'ext2',
    label: i18n('entities.secondaryCancers.fields.ext2'),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.ext2'),
      {},
    ),
  },
  {
    name: 'ext3',
    label: i18n('entities.secondaryCancers.fields.ext3'),
    schema: schemas.string(
      i18n('entities.secondaryCancers.fields.ext3'),
      {},
    ),
  },
];
