import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.economicBurden.fields.id'),
  },
  {
    name: 'regionSel',
    label: i18n('entities.economicBurden.fields.regionSel'),
  },
  {
    name: 'yearSel',
    label: i18n('entities.economicBurden.fields.yearSel'),
  },
  {
    name: 'patientPopSel',
    label: i18n(
      'entities.economicBurden.fields.patientPopSel',
    ),
  },
  {
    name: 'dataSourceSel',
    label: i18n(
      'entities.economicBurden.fields.dataSourceSel',
    ),
  },
  {
    name: 'outcomeSel',
    label: i18n(
      'entities.economicBurden.fields.outcomeSel',
    ),
  },
  {
    name: 'study',
    label: i18n('entities.economicBurden.fields.study'),
  },
  {
    name: 'year',
    label: i18n('entities.economicBurden.fields.year'),
  },
  {
    name: 'studyType',
    label: i18n('entities.economicBurden.fields.studyType'),
  },
  {
    name: 'country',
    label: i18n('entities.economicBurden.fields.country'),
  },
  {
    name: 'data',
    label: i18n('entities.economicBurden.fields.data'),
  },
  {
    name: 'timePeriod',
    label: i18n(
      'entities.economicBurden.fields.timePeriod',
    ),
  },
  {
    name: 'patientPopulation2',
    label: i18n(
      'entities.economicBurden.fields.patientPopulation2',
    ),
  },
  {
    name: 'economicEndpoints',
    label: i18n(
      'entities.economicBurden.fields.economicEndpoints',
    ),
  },
  {
    name: 'economicResults',
    label: i18n(
      'entities.economicBurden.fields.economicResults',
    ),
  },
  {
    name: 'hruEndpoints',
    label: i18n(
      'entities.economicBurden.fields.hruEndpoints',
    ),
  },
  {
    name: 'hruResults',
    label: i18n(
      'entities.economicBurden.fields.hruResults',
    ),
  },
  {
    name: 'count',
    label: i18n('entities.economicBurden.fields.count'),
  },
  {
    name: 'rowIsVisible',
    label: i18n(
      'entities.economicBurden.fields.rowIsVisible',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rowColor',
    label: i18n('entities.economicBurden.fields.rowColor'),
  },
  {
    name: 'year3',
    label: i18n('entities.economicBurden.fields.year3'),
  },
  {
    name: 'africa',
    label: i18n('entities.economicBurden.fields.africa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asiaPacific',
    label: i18n(
      'entities.economicBurden.fields.asiaPacific',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'australia',
    label: i18n('entities.economicBurden.fields.australia'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'europe',
    label: i18n('entities.economicBurden.fields.europe'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'latinAmerica',
    label: i18n(
      'entities.economicBurden.fields.latinAmerica',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'middleEast',
    label: i18n(
      'entities.economicBurden.fields.middleEast',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'usa',
    label: i18n('entities.economicBurden.fields.usa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'canada',
    label: i18n('entities.economicBurden.fields.canada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result',
    label: i18n('entities.economicBurden.fields.result'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'cll',
    label: i18n('entities.economicBurden.fields.cll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rrCll',
    label: i18n('entities.economicBurden.fields.rrCll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'claimsDatabase',
    label: i18n(
      'entities.economicBurden.fields.claimsDatabase',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'eap',
    label: i18n('entities.economicBurden.fields.eap'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'literature',
    label: i18n(
      'entities.economicBurden.fields.literature',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'medicalCenters',
    label: i18n(
      'entities.economicBurden.fields.medicalCenters',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'registry',
    label: i18n('entities.economicBurden.fields.registry'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result2',
    label: i18n('entities.economicBurden.fields.result2'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'economicOutcome',
    label: i18n(
      'entities.economicBurden.fields.economicOutcome',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'hruOutcome',
    label: i18n(
      'entities.economicBurden.fields.hruOutcome',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result3',
    label: i18n('entities.economicBurden.fields.result3'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n('entities.economicBurden.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.economicBurden.fields.ext2'),
  },
  {
    name: 'ext3',
    label: i18n('entities.economicBurden.fields.ext3'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.economicBurden.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.economicBurden.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
