import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import incidence from 'src/modules/incidence/incidenceReducers';
import survival from 'src/modules/survival/survivalReducers';
import prognosticCharacteristics from 'src/modules/prognosticCharacteristics/prognosticCharacteristicsReducers';
import uiEpidemiology from 'src/modules/uiEpidemiology/uiEpidemiologyReducers';
import costs from 'src/modules/costs/costsReducers';
import resourceUtilization from 'src/modules/resourceUtilization/resourceUtilizationReducers';
import models from 'src/modules/models/modelsReducers';
import economic from 'src/modules/economic/economicReducers';
import patient from 'src/modules/patient/patientReducers';
import secondaryCancers from 'src/modules/secondaryCancers/secondaryCancersReducers';
import complications from 'src/modules/complications/complicationsReducers';
import economicBurden from 'src/modules/economicBurden/economicBurdenReducers';
import patientBurden from 'src/modules/patientBurden/patientBurdenReducers';
import realWorldTreatmentOutcomes from 'src/modules/realWorldTreatmentOutcomes/realWorldTreatmentOutcomesReducers';
import uiComparativeEffectiveness from 'src/modules/uiComparativeEffectiveness/uiComparativeEffectivenessReducers';
import realWorldOutcomes from 'src/modules/realWorldOutcomes/realWorldOutcomesReducers';
import realWorldTreatmentPatterns from 'src/modules/realWorldTreatmentPatterns/realWorldTreatmentPatternsReducers';
import uiCostsHru from 'src/modules/uiCostsHru/uiCostsHruReducers';
import realWorldTreatmentOutcomes2 from 'src/modules/realWorldTreatmentOutcomes2/realWorldTreatmentOutcomes2Reducers';
import searchStrategy from 'src/modules/searchStrategies/searchStrategyReducers';
import comparativeEffectiveness from 'src/modules/comparativeEffectiveness/comparativeEffectivenessReducers';
import searchStrategyRwo from 'src/modules/searchStrategyRwo/searchStrategyRwoReducers';
import searchDataEconomic from 'src/modules/searchDataEconomic/searchDataEconomicReducers';
import uiReferences from 'src/modules/uiReferences/uiReferencesReducers';
import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    auditLog,
    settings,
    incidence,
    survival,
    prognosticCharacteristics,
    uiEpidemiology,
    costs,
    resourceUtilization,
    models,
    economic,
    patient,
    secondaryCancers,
    complications,
    economicBurden,
    patientBurden,
    realWorldTreatmentOutcomes,
    uiComparativeEffectiveness,
    realWorldOutcomes,
    realWorldTreatmentPatterns,
    uiCostsHru,
    realWorldTreatmentOutcomes2,
    searchStrategy,

    comparativeEffectiveness,
    searchStrategyRwo,
    searchDataEconomic,
    uiReferences,
  });
