import list from 'src/modules/searchStrategyRwo/list/searchStrategyRwoListReducers';
import form from 'src/modules/searchStrategyRwo/form/searchStrategyRwoFormReducers';
import view from 'src/modules/searchStrategyRwo/view/searchStrategyRwoViewReducers';
import destroy from 'src/modules/searchStrategyRwo/destroy/searchStrategyRwoDestroyReducers';
import importerReducer from 'src/modules/searchStrategyRwo/importer/searchStrategyRwoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
