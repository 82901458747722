import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/realWorldTreatmentPatterns/importer/realWorldTreatmentPatternsImporterSelectors';
import RealWorldTreatmentPatternsService from 'src/modules/realWorldTreatmentPatterns/realWorldTreatmentPatternsService';
import fields from 'src/modules/realWorldTreatmentPatterns/importer/realWorldTreatmentPatternsImporterFields';
import { i18n } from 'src/i18n';

const realWorldTreatmentPatternsImporterActions = importerActions(
  'REALWORLDTREATMENTPATTERNS_IMPORTER',
  selectors,
  RealWorldTreatmentPatternsService.import,
  fields,
  i18n('entities.realWorldTreatmentPatterns.importer.fileName'),
);

export default realWorldTreatmentPatternsImporterActions;