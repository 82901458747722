import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class SearchStrategyService {
  static async list(filter) {
    console.log('searchStrategyService.tsx - 6');
    console.log('Filter:');
    console.log(filter);

    const params = {
      filter,
      // orderBy,
      // limit,
      // offset,
    };

    console.log(params);

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/search-strategy`,
      {
        params,
      },
    );

    console.log('Response data:');
    console.log(response.data);

    return { rows: response.data, count: 1 };
  }
}
