import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/models/importer/modelsImporterSelectors';
import ModelsService from 'src/modules/models/modelsService';
import fields from 'src/modules/models/importer/modelsImporterFields';
import { i18n } from 'src/i18n';

const modelsImporterActions = importerActions(
  'MODELS_IMPORTER',
  selectors,
  ModelsService.import,
  fields,
  i18n('entities.models.importer.fileName'),
);

export default modelsImporterActions;