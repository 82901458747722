import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/patientBurden/importer/patientBurdenImporterSelectors';
import PatientBurdenService from 'src/modules/patientBurden/patientBurdenService';
import fields from 'src/modules/patientBurden/importer/patientBurdenImporterFields';
import { i18n } from 'src/i18n';

const patientBurdenImporterActions = importerActions(
  'PATIENTBURDEN_IMPORTER',
  selectors,
  PatientBurdenService.import,
  fields,
  i18n('entities.patientBurden.importer.fileName'),
);

export default patientBurdenImporterActions;