import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/searchStrategyRwo/importer/searchStrategyRwoImporterSelectors';
import SearchStrategyRwoService from 'src/modules/searchStrategyRwo/searchStrategyRwoService';
import fields from 'src/modules/searchStrategyRwo/importer/searchStrategyRwoImporterFields';
import { i18n } from 'src/i18n';

const searchStrategyRwoImporterActions = importerActions(
  'SEARCHSTRATEGYRWO_IMPORTER',
  selectors,
  SearchStrategyRwoService.import,
  fields,
  i18n('entities.searchStrategyRwo.importer.fileName'),
);

export default searchStrategyRwoImporterActions;