import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.incidence.fields.id'),
  },
  {
    name: 'regionSel',
    label: i18n('entities.incidence.fields.regionSel'),
  },
  {
    name: 'yearSel',
    label: i18n('entities.incidence.fields.yearSel'),
  },
  {
    name: 'patientPopSel',
    label: i18n('entities.incidence.fields.patientPopSel'),
  },
  {
    name: 'dataSourceSel',
    label: i18n('entities.incidence.fields.dataSourceSel'),
  },
  {
    name: 'outcomeSel',
    label: i18n('entities.incidence.fields.outcomeSel'),
  },
  {
    name: 'study',
    label: i18n('entities.incidence.fields.study'),
  },
  {
    name: 'year',
    label: i18n('entities.incidence.fields.year'),
  },
  {
    name: 'studyType',
    label: i18n('entities.incidence.fields.studyType'),
  },
  {
    name: 'funder',
    label: i18n('entities.incidence.fields.funder'),
  },
  {
    name: 'country',
    label: i18n('entities.incidence.fields.country'),
  },
  {
    name: 'data',
    label: i18n('entities.incidence.fields.data'),
  },
  {
    name: 'timePeriod',
    label: i18n('entities.incidence.fields.timePeriod'),
  },
  {
    name: 'patientPopulation',
    label: i18n(
      'entities.incidence.fields.patientPopulation',
    ),
  },
  {
    name: 'endpoints',
    label: i18n('entities.incidence.fields.endpoints'),
  },
  {
    name: 'results',
    label: i18n('entities.incidence.fields.results'),
  },
  {
    name: 'count',
    label: i18n('entities.incidence.fields.count'),
  },
  {
    name: 'rowIsVisible',
    label: i18n('entities.incidence.fields.rowIsVisible'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rowColor',
    label: i18n('entities.incidence.fields.rowColor'),
  },
  {
    name: 'africa',
    label: i18n('entities.incidence.fields.africa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asiaPacific',
    label: i18n('entities.incidence.fields.asiaPacific'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'australia',
    label: i18n('entities.incidence.fields.australia'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'latinAmerica',
    label: i18n('entities.incidence.fields.latinAmerica'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'middleEast',
    label: i18n('entities.incidence.fields.middleEast'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'usa',
    label: i18n('entities.incidence.fields.usa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'canada',
    label: i18n('entities.incidence.fields.canada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result1',
    label: i18n('entities.incidence.fields.result1'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'year',
    label: i18n('entities.incidence.fields.year'),
  },
  {
    name: 'result2',
    label: i18n('entities.incidence.fields.result2'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'cll',
    label: i18n('entities.incidence.fields.cll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rrCll',
    label: i18n('entities.incidence.fields.rrCll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result3',
    label: i18n('entities.incidence.fields.result3'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'claimsDatabase',
    label: i18n('entities.incidence.fields.claimsDatabase'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'eap',
    label: i18n('entities.incidence.fields.eap'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'literature',
    label: i18n('entities.incidence.fields.literature'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'medicalCenters',
    label: i18n('entities.incidence.fields.medicalCenters'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'registry',
    label: i18n('entities.incidence.fields.registry'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result4',
    label: i18n('entities.incidence.fields.result4'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'incidence',
    label: i18n('entities.incidence.fields.incidence'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'prevalence',
    label: i18n('entities.incidence.fields.prevalence'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result5',
    label: i18n('entities.incidence.fields.result5'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n('entities.incidence.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.incidence.fields.ext2'),
  },
  {
    name: 'ext3',
    label: i18n('entities.incidence.fields.ext3'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.incidence.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.incidence.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
