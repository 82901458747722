import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
//import datas from "../../sample3.json"

export default class SecondaryCancersService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

   const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/slr/${id}`,
      body,
    );
    

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

   const response = await authAxios.delete(
      `/tenant/${tenantId}/slr`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

   const response = await authAxios.post(
      `/tenant/${tenantId}/slr`,
      body,
    );

    return response.data ;
  }

  static async import(values, importHash) {
   const body = {
      data: values,
      importHash,
    };

   const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/slr/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/slr/${id}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
   const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    
    const response = await authAxios.get(
     `/tenant/${tenantId}/slr`,
     {
        params,
      },
   );
   console.log(response);

    //return response.data;
    return { rows: response.data, count: 1 };
   
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/slr`,
      {
        params,
      },
    );

    return response.data;
  }
}
