import React, { useLayoutEffect, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import authAxios from 'src/modules/shared/axios/authAxios'
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'
import menus from 'src/view/menus';
import clsx from 'clsx';
import {
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
} from '@material-ui/core';

const drawerWidth = 300;

const useStyles = makeStyles( (theme) =>
({
	toolbar : theme.mixins.toolbar,

	drawer :
	{
		width : drawerWidth,
		flexShrink : 0
	},

	drawerPaper :
	{
		width : drawerWidth
	},

	active :
	{
		color : theme.palette.primary.main,
		fontWeight : theme.typography.fontWeightMedium
	},

	listContainer : 
	{
		padding : '2vh 0 4vh 1vh !important'
	},

	listItemIcon :
	{
		minWidth : '48px'
	},

	listItemDisabled :
	{
		opacity : 0.5
	}
}))

function Menu(props)
{
	const classes = useStyles();
	const dispatch = useDispatch();

	const menuVisible = useSelector( layoutSelectors.selectMenuVisible );
	const [allowedCategories, setAllowedCategories] = React.useState(null)

	useEffect( ()=>
	{
		if ( allowedCategories === null )
		{
			const tenantId = AuthCurrentTenant.get()

			authAxios.get( `/tenant/${tenantId}/categories`, {} ).then( (rsp) =>
			{
				setAllowedCategories( rsp.data )
				localStorage.setItem( 'categories', rsp.data )
			})
		}
	}, [ props, allowedCategories ])

	useLayoutEffect(() =>
	{
		const toggleMenuOnResize = () =>
		{
			(window as any).innerWidth < 576 ? dispatch(actions.doHideMenu()) : dispatch(actions.doShowMenu())
		};

		toggleMenuOnResize();

		(window as any).addEventListener( 'resize', toggleMenuOnResize );

		return () =>
		{
			(window as any).removeEventListener( 'resize', toggleMenuOnResize );
		};
	}, [dispatch]);

	const selectedSubKeys = (submenus) =>
	{
		let url = props.url;
		console.log(url)
        if (url=='/')
		   url='/summary';
		if (submenus)
		{
			const match = submenus.find((option) =>
			{
				return ( url === option.path || url.startsWith(option.path + '/') )
			});

			if (match)
				return [match.path];
		}

		return [];
	};

	const selectedKeys = (menu) =>
	{
		const ssk = selectedSubKeys(menu.subMenus);

		// if (!menu.subMenus) {
		//   return true;
		// }

		if (ssk.length > 0)
			return true;

		return false;
	};

	const CustomRouterLink = (props) =>
	(
		<div style={{ flexGrow: 1 }}>
			<Link style={{ textDecoration: 'none', color: 'inherit' }} {...props} />
		</div>
	);

	if (!menuVisible)
		return null;

	return (
	    allowedCategories?.length > 0 &&
		<Drawer
			className={classes.drawer}
			variant="permanent"
			anchor="left"
			open={true}
			classes={{ paper: classes.drawerPaper }}
		>
			<div className={classes.toolbar}></div>

			<List className={classes.listContainer}>
			{ menus.map( (m) => (
			    m.permissionRequired !== null &&
				<CustomRouterLink key={m.path} to={m.path}>
				    <ListSubheader>
					<ListItemText className={ clsx({ [classes.active]: selectedKeys(m) }) } >
						{m.label}
					</ListItemText>

					{ m.subMenus && m.subMenus.map((s) => (
					    s.permissionRequired !== null &&
						<CustomRouterLink key={s.path} to={s.path}>
						    <ListItem button>
							<ListItemText className={ clsx({ [classes.active]: selectedSubKeys(m.subMenus).includes(s.path) })} >
								{s.label}
							</ListItemText>
						    </ListItem>
						</CustomRouterLink>
					))}
				    </ListSubheader>
				</CustomRouterLink>
			))}
			</List>
		</Drawer>
	);
}

export default Menu;
