import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.comparativeEffectiveness.fields.id'),
  },
  {
    name: 'area',
    label: i18n('entities.comparativeEffectiveness.fields.area'),
  },
  {
    name: 'subarea',
    label: i18n('entities.comparativeEffectiveness.fields.subarea'),
  },
  {
    name: 'regionSel',
    label: i18n('entities.comparativeEffectiveness.fields.regionSel'),
  },
  {
    name: 'yearSel',
    label: i18n('entities.comparativeEffectiveness.fields.yearSel'),
  },
  {
    name: 'patientPopSel',
    label: i18n('entities.comparativeEffectiveness.fields.patientPopSel'),
  },
  {
    name: 'dataSourceSel',
    label: i18n('entities.comparativeEffectiveness.fields.dataSourceSel'),
  },
  {
    name: 'outcomeSel',
    label: i18n('entities.comparativeEffectiveness.fields.outcomeSel'),
  },
  {
    name: 'drugSel',
    label: i18n('entities.comparativeEffectiveness.fields.drugSel'),
  },
  {
    name: 'author',
    label: i18n('entities.comparativeEffectiveness.fields.author'),
  },
  {
    name: 'year',
    label: i18n('entities.comparativeEffectiveness.fields.year'),
  },
  {
    name: 'funder',
    label: i18n('entities.comparativeEffectiveness.fields.funder'),
  },
  {
    name: 'country',
    label: i18n('entities.comparativeEffectiveness.fields.country'),
  },
  {
    name: 'studyMethod',
    label: i18n('entities.comparativeEffectiveness.fields.studyMethod'),
  },
  {
    name: 'data',
    label: i18n('entities.comparativeEffectiveness.fields.data'),
  },
  {
    name: 'timePeriod',
    label: i18n('entities.comparativeEffectiveness.fields.timePeriod'),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.comparativeEffectiveness.fields.patientPopulation'),
  },
  {
    name: 'analysis',
    label: i18n('entities.comparativeEffectiveness.fields.analysis'),
  },
  {
    name: 'outcomes',
    label: i18n('entities.comparativeEffectiveness.fields.outcomes'),
  },
  {
    name: 'results',
    label: i18n('entities.comparativeEffectiveness.fields.results'),
  },
  {
    name: 'resultInfo',
    label: i18n('entities.comparativeEffectiveness.fields.resultInfo'),
  },
  {
    name: 'count',
    label: i18n('entities.comparativeEffectiveness.fields.count'),
  },
  {
    name: 'rowIsVisible',
    label: i18n('entities.comparativeEffectiveness.fields.rowIsVisible'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rowColor',
    label: i18n('entities.comparativeEffectiveness.fields.rowColor'),
  },
  {
    name: 'author2',
    label: i18n('entities.comparativeEffectiveness.fields.author2'),
  },
  {
    name: 'africa',
    label: i18n('entities.comparativeEffectiveness.fields.africa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asiaPacific',
    label: i18n('entities.comparativeEffectiveness.fields.asiaPacific'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'australia',
    label: i18n('entities.comparativeEffectiveness.fields.australia'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'europe',
    label: i18n('entities.comparativeEffectiveness.fields.europe'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'latinAmerica',
    label: i18n('entities.comparativeEffectiveness.fields.latinAmerica'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'middleEast',
    label: i18n('entities.comparativeEffectiveness.fields.middleEast'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'usa',
    label: i18n('entities.comparativeEffectiveness.fields.usa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'canada',
    label: i18n('entities.comparativeEffectiveness.fields.canada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result',
    label: i18n('entities.comparativeEffectiveness.fields.result'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'value',
    label: i18n('entities.comparativeEffectiveness.fields.value'),
  },
  {
    name: 'result2',
    label: i18n('entities.comparativeEffectiveness.fields.result2'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'cll',
    label: i18n('entities.comparativeEffectiveness.fields.cll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rrCll',
    label: i18n('entities.comparativeEffectiveness.fields.rrCll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result3',
    label: i18n('entities.comparativeEffectiveness.fields.result3'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'response',
    label: i18n('entities.comparativeEffectiveness.fields.response'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'overallSurvival',
    label: i18n('entities.comparativeEffectiveness.fields.overallSurvival'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'progressionFreeSurvival',
    label: i18n('entities.comparativeEffectiveness.fields.progressionFreeSurvival'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'adverseEvents',
    label: i18n('entities.comparativeEffectiveness.fields.adverseEvents'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'adherence',
    label: i18n('entities.comparativeEffectiveness.fields.adherence'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'discontinuation',
    label: i18n('entities.comparativeEffectiveness.fields.discontinuation'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'qol',
    label: i18n('entities.comparativeEffectiveness.fields.qol'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result4',
    label: i18n('entities.comparativeEffectiveness.fields.result4'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'acalabrutinib',
    label: i18n('entities.comparativeEffectiveness.fields.acalabrutinib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'duvelisib',
    label: i18n('entities.comparativeEffectiveness.fields.duvelisib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ibrutinib',
    label: i18n('entities.comparativeEffectiveness.fields.ibrutinib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'idelalisib',
    label: i18n('entities.comparativeEffectiveness.fields.idelalisib'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'lenalidomide',
    label: i18n('entities.comparativeEffectiveness.fields.lenalidomide'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'obinutuzumab',
    label: i18n('entities.comparativeEffectiveness.fields.obinutuzumab'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ofatumumab',
    label: i18n('entities.comparativeEffectiveness.fields.ofatumumab'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'venetoclax',
    label: i18n('entities.comparativeEffectiveness.fields.venetoclax'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result5',
    label: i18n('entities.comparativeEffectiveness.fields.result5'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n('entities.comparativeEffectiveness.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.comparativeEffectiveness.fields.ext2'),
  },
  {
    name: 'ext3',
    label: i18n('entities.comparativeEffectiveness.fields.ext3'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.comparativeEffectiveness.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.comparativeEffectiveness.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
