import list from 'src/modules/uiComparativeEffectiveness/list/uiComparativeEffectivenessListReducers';
import form from 'src/modules/uiComparativeEffectiveness/form/uiComparativeEffectivenessFormReducers';
import view from 'src/modules/uiComparativeEffectiveness/view/uiComparativeEffectivenessViewReducers';
import destroy from 'src/modules/uiComparativeEffectiveness/destroy/uiComparativeEffectivenessDestroyReducers';
import importerReducer from 'src/modules/uiComparativeEffectiveness/importer/uiComparativeEffectivenessImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
