import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.id',
    ),
  },
  {
    name: 'area',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.area',
    ),
  },
  {
    name: 'subarea',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.subarea',
    ),
  },
  {
    name: 'regionSel',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.regionSel',
    ),
  },
  {
    name: 'yearSel',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.yearSel',
    ),
  },
  {
    name: 'patientPopSel',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.patientPopSel',
    ),
  },
  {
    name: 'dataSourceSel',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.dataSourceSel',
    ),
  },
  {
    name: 'drugSel',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.drugSel',
    ),
  },
  {
    name: 'author',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.author',
    ),
  },
  {
    name: 'year',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.year',
    ),
  },
  {
    name: 'studyType',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.studyType',
    ),
  },
  {
    name: 'funder',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.funder',
    ),
  },
  {
    name: 'country',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.country',
    ),
  },
  {
    name: 'data',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.data',
    ),
  },
  {
    name: 'timePeriod',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.timePeriod',
    ),
  },
  {
    name: 'patientPopulation',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.patientPopulation',
    ),
  },
  {
    name: 'measure',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.measure',
    ),
  },
  {
    name: 'results',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.results',
    ),
  },
  {
    name: 'interventions',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.interventions',
    ),
  },
  {
    name: 'outcomes',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.outcomes',
    ),
  },
  {
    name: 'count',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.count',
    ),
  },
  {
    name: 'rowIsVisible',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.rowIsVisible',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rowColor',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.rowColor',
    ),
  },
  {
    name: 'author2',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.author2',
    ),
  },
  {
    name: 'africa',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.africa',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asiaPacific',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.asiaPacific',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'australia',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.australia',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'europe',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.europe',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'latinAmerica',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.latinAmerica',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'middleEast',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.middleEast',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'usa',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.usa',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'canada',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.canada',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.result',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'year',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.year',
    ),
  },
  {
    name: 'result2',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.result2',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'resultTreatPatX',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.resultTreatPatX',
    ),
  },
  {
    name: 'cll',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.cll',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rrCll',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.rrCll',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result3',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.result3',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'dataSource',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.dataSource',
    ),
  },
  {
    name: 'claimsDatabase',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.claimsDatabase',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'eap',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.eap',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'literature',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.literature',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'medicalCenters',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.medicalCenters',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'registry',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.registry',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result4',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.result4',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'acalabrutinib',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.acalabrutinib',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'bendamustine',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.bendamustine',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'duvelisib',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.duvelisib',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ibrutinib',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.ibrutinib',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'idelalisib',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.idelalisib',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'lenalidomide',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.lenalidomide',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'obinutuzumab',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.obinutuzumab',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ofatumumab',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.ofatumumab',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'venetoclax',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.venetoclax',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result5',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.result5',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.ext1',
    ),
  },
  {
    name: 'ext2',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.ext2',
    ),
  },
  {
    name: 'ext3',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.ext3',
    ),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.realWorldTreatmentPatterns.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];
