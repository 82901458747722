import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/economicBurden/importer/economicBurdenImporterSelectors';
import EconomicBurdenService from 'src/modules/economicBurden/economicBurdenService';
import fields from 'src/modules/economicBurden/importer/economicBurdenImporterFields';
import { i18n } from 'src/i18n';

const economicBurdenImporterActions = importerActions(
  'ECONOMICBURDEN_IMPORTER',
  selectors,
  EconomicBurdenService.import,
  fields,
  i18n('entities.economicBurden.importer.fileName'),
);

export default economicBurdenImporterActions;