import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.complications.fields.id'),
  },
  {
    name: 'methodologySel',
    label: i18n('entities.complications.fields.methodologySel'),
  },
  {
    name: 'yearSel',
    label: i18n('entities.complications.fields.yearSel'),
  },
  {
    name: 'outcomeMulti',
    label: i18n('entities.complications.fields.outcomeMulti'),
  },
  {
    name: 'searchDatabaseSel',
    label: i18n('entities.complications.fields.searchDatabaseSel'),
  },
  {
    name: 'studySettingSel',
    label: i18n('entities.complications.fields.studySettingSel'),
  },
  {
    name: 'studyPopulationSel',
    label: i18n('entities.complications.fields.studyPopulationSel'),
  },
  {
    name: 'studySampleSel',
    label: i18n('entities.complications.fields.studySampleSel'),
  },
  {
    name: 'interventionSel',
    label: i18n('entities.complications.fields.interventionSel'),
  },
  
  {
    name: 'dataSourceSel',
    label: i18n('entities.complications.fields.dataSourceSel'),
  },
  {
    name: 'author',
    label: i18n('entities.complications.fields.author'),
  },
  {
    name: 'Year',
    label: i18n('entities.complications.fields.Year'),
    
  },
 
  {
    name: 'Methodology',
    label: i18n('entities.complications.fields.Methodology'),
    
  },
  {
    name: 'studySetting',
    label: i18n('entities.complications.fields.studySetting'),
    
  },
  {
    name: 'dataSource',
    label: i18n('entities.complications.fields.dataSource'),
    
  },
  {
    name: 'studyTimeline',
    label: i18n('entities.complications.fields.studyTimeline'),
    
  },
  {
    name: 'inclusionCriteria',
    label: i18n('entities.complications.fields.inclusionCriteria'),
    
  },
  {
    name: 'exclusionCriteria',
    label: i18n('entities.complications.fields.exclusionCriteria'),
    
  },
  {
    name: 'studyPopulation',
    label: i18n('entities.complications.fields.studyPopulation'),
    
  },
  {
    name: 'studySampleSize',
    label: i18n('entities.complications.fields.studySampleSize'),
    
  },
  {
    name: 'age',
    label: i18n('entities.secondaryCancers.fields.age'),
    
  },
  {
    name: 'gender',
    label: i18n('entities.complications.fields.gender'),
    
  },
  {
    name: 'stsScoreOrSurgicalRisk',
    label: i18n('entities.complications.fields.stsScoreOrSurgicalRisk'),
    
  },
  {
    name: 'followUp',
    label: i18n('entities.complications.fields.followUp'),
    
  }, {
    name: 'intervention',
    label: i18n('entities.complications.fields.intervention'),
    
  },
  {
    name: 'endpointCategory',
    label: i18n('entities.complications.fields.endpointCategory'),
    
  },



  {
    name: 'endpointDefinition',
    label: i18n('entities.complications.fields.endpointDefinition'),
  },
  {
    name: 'result',
    label: i18n('entities.complications.fields.result'),
  },
  {
    name: 'country',
    label: i18n('entities.complications.fields.DataSource'),
  },
  {
    name: 'data',
    label: i18n('entities.complications.fields.StudyTimeline'),
  },
  {
    name: 'timePeriod',
    label: i18n('entities.complications.fields.Studysamplesize'),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.complications.fields.patientPopulation'),
  },
  {
    name: 'secondaryCancer',
    label: i18n('entities.complications.fields.secondaryCancer'),
  },
  {
    name: 'endpoints',
    label: i18n('entities.complications.fields.endpoints'),
  },
  
  {
    name: 'count',
    label: i18n('entities.complications.fields.count'),
  },
  {
    name: 'rowIsVisible',
    label: i18n('entities.complications.fields.rowIsVisible'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rowColor',
    label: i18n('entities.complications.fields.rowColor'),
  },
  {
    name: 'country2',
    label: i18n('entities.complications.fields.country2'),
  },
  {
    name: 'africa',
    label: i18n('entities.complications.fields.africa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asiaPacific',
    label: i18n('entities.complications.fields.asiaPacific'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'australia',
    label: i18n('entities.complications.fields.australia'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'europe',
    label: i18n('entities.complications.fields.europe'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'latinAmerica',
    label: i18n('entities.complications.fields.latinAmerica'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'middleEast',
    label: i18n('entities.complications.fields.middleEast'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'usa',
    label: i18n('entities.complications.fields.usa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'canada',
    label: i18n('entities.complications.fields.canada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result',
    label: i18n('entities.complications.fields.result'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'value',
    label: i18n('entities.complications.fields.value'),
  },
  {
    name: 'result2',
    label: i18n('entities.complications.fields.result2'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'cll',
    label: i18n('entities.complications.fields.cll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rrCll',
    label: i18n('entities.complications.fields.rrCll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'registryDb',
    label: i18n('entities.complications.fields.registryDb'),
  },
  {
    name: 'claimsDatabase',
    label: i18n('entities.complications.fields.claimsDatabase'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'eap',
    label: i18n('entities.complications.fields.eap'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'literature',
    label: i18n('entities.complications.fields.literature'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'medicalCenters',
    label: i18n('entities.complications.fields.medicalCenters'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'registry',
    label: i18n('entities.complications.fields.registry'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result4',
    label: i18n('entities.complications.fields.result4'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n('entities.complications.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.complications.fields.ext2'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.complications.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.complications.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
