import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import modelsEnumerators from 'src/modules/models/modelsEnumerators';

export default [
  {
    name: 'table',
    label: i18n('entities.models.fields.table'),
    schema: schemas.string(
      i18n('entities.models.fields.table'),
      {},
    ),
  },
  {
    name: 'area',
    label: i18n('entities.models.fields.area'),
    schema: schemas.string(
      i18n('entities.models.fields.area'),
      {},
    ),
  },
  {
    name: 'regionSel',
    label: i18n('entities.models.fields.regionSel'),
    schema: schemas.enumerator(
      i18n('entities.models.fields.regionSel'),
      {
        "options": modelsEnumerators.regionSel
      },
    ),
  },
  {
    name: 'yearSel',
    label: i18n('entities.models.fields.yearSel'),
    schema: schemas.enumerator(
      i18n('entities.models.fields.yearSel'),
      {
        "options": modelsEnumerators.yearSel
      },
    ),
  },
  {
    name: 'patientPopSel',
    label: i18n('entities.models.fields.patientPopSel'),
    schema: schemas.enumerator(
      i18n('entities.models.fields.patientPopSel'),
      {
        "options": modelsEnumerators.patientPopSel
      },
    ),
  },
  {
    name: 'drugSel',
    label: i18n('entities.models.fields.drugSel'),
    schema: schemas.enumerator(
      i18n('entities.models.fields.drugSel'),
      {
        "options": modelsEnumerators.drugSel
      },
    ),
  },
  {
    name: 'modelTypeSel',
    label: i18n('entities.models.fields.modelTypeSel'),
    schema: schemas.enumerator(
      i18n('entities.models.fields.modelTypeSel'),
      {
        "options": modelsEnumerators.modelTypeSel
      },
    ),
  },
  {
    name: 'author',
    label: i18n('entities.models.fields.author'),
    schema: schemas.string(
      i18n('entities.models.fields.author'),
      {},
    ),
  },
  {
    name: 'year',
    label: i18n('entities.models.fields.year'),
    schema: schemas.string(
      i18n('entities.models.fields.year'),
      {},
    ),
  },
  {
    name: 'publicationType',
    label: i18n('entities.models.fields.publicationType'),
    schema: schemas.string(
      i18n('entities.models.fields.publicationType'),
      {},
    ),
  },
  {
    name: 'funder',
    label: i18n('entities.models.fields.funder'),
    schema: schemas.string(
      i18n('entities.models.fields.funder'),
      {},
    ),
  },
  {
    name: 'country',
    label: i18n('entities.models.fields.country'),
    schema: schemas.string(
      i18n('entities.models.fields.country'),
      {},
    ),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.models.fields.patientPopulation'),
    schema: schemas.string(
      i18n('entities.models.fields.patientPopulation'),
      {},
    ),
  },
  {
    name: 'interventions',
    label: i18n('entities.models.fields.interventions'),
    schema: schemas.string(
      i18n('entities.models.fields.interventions'),
      {},
    ),
  },
  {
    name: 'data',
    label: i18n('entities.models.fields.data'),
    schema: schemas.string(
      i18n('entities.models.fields.data'),
      {},
    ),
  },
  {
    name: 'outcomes',
    label: i18n('entities.models.fields.outcomes'),
    schema: schemas.string(
      i18n('entities.models.fields.outcomes'),
      {},
    ),
  },
  {
    name: 'results',
    label: i18n('entities.models.fields.results'),
    schema: schemas.string(
      i18n('entities.models.fields.results'),
      {},
    ),
  },
  {
    name: 'count',
    label: i18n('entities.models.fields.count'),
    schema: schemas.integer(
      i18n('entities.models.fields.count'),
      {},
    ),
  },
  {
    name: 'rowIsVisible',
    label: i18n('entities.models.fields.rowIsVisible'),
    schema: schemas.boolean(
      i18n('entities.models.fields.rowIsVisible'),
      {},
    ),
  },
  {
    name: 'rowColor',
    label: i18n('entities.models.fields.rowColor'),
    schema: schemas.string(
      i18n('entities.models.fields.rowColor'),
      {},
    ),
  },
  {
    name: 'author2',
    label: i18n('entities.models.fields.author2'),
    schema: schemas.string(
      i18n('entities.models.fields.author2'),
      {},
    ),
  },
  {
    name: 'africa',
    label: i18n('entities.models.fields.africa'),
    schema: schemas.boolean(
      i18n('entities.models.fields.africa'),
      {},
    ),
  },
  {
    name: 'asiaPacific',
    label: i18n('entities.models.fields.asiaPacific'),
    schema: schemas.boolean(
      i18n('entities.models.fields.asiaPacific'),
      {},
    ),
  },
  {
    name: 'australia',
    label: i18n('entities.models.fields.australia'),
    schema: schemas.boolean(
      i18n('entities.models.fields.australia'),
      {},
    ),
  },
  {
    name: 'europe',
    label: i18n('entities.models.fields.europe'),
    schema: schemas.boolean(
      i18n('entities.models.fields.europe'),
      {},
    ),
  },
  {
    name: 'latinAmerica',
    label: i18n('entities.models.fields.latinAmerica'),
    schema: schemas.boolean(
      i18n('entities.models.fields.latinAmerica'),
      {},
    ),
  },
  {
    name: 'middleEast',
    label: i18n('entities.models.fields.middleEast'),
    schema: schemas.boolean(
      i18n('entities.models.fields.middleEast'),
      {},
    ),
  },
  {
    name: 'usa',
    label: i18n('entities.models.fields.usa'),
    schema: schemas.boolean(
      i18n('entities.models.fields.usa'),
      {},
    ),
  },
  {
    name: 'canada',
    label: i18n('entities.models.fields.canada'),
    schema: schemas.boolean(
      i18n('entities.models.fields.canada'),
      {},
    ),
  },
  {
    name: 'result',
    label: i18n('entities.models.fields.result'),
    schema: schemas.boolean(
      i18n('entities.models.fields.result'),
      {},
    ),
  },
  {
    name: 'valueYear',
    label: i18n('entities.models.fields.valueYear'),
    schema: schemas.string(
      i18n('entities.models.fields.valueYear'),
      {},
    ),
  },
  {
    name: 'result2',
    label: i18n('entities.models.fields.result2'),
    schema: schemas.boolean(
      i18n('entities.models.fields.result2'),
      {},
    ),
  },
  {
    name: 'cll',
    label: i18n('entities.models.fields.cll'),
    schema: schemas.boolean(
      i18n('entities.models.fields.cll'),
      {},
    ),
  },
  {
    name: 'rrCli',
    label: i18n('entities.models.fields.rrCli'),
    schema: schemas.boolean(
      i18n('entities.models.fields.rrCli'),
      {},
    ),
  },
  {
    name: 'result3',
    label: i18n('entities.models.fields.result3'),
    schema: schemas.boolean(
      i18n('entities.models.fields.result3'),
      {},
    ),
  },
  {
    name: 'ceaModel',
    label: i18n('entities.models.fields.ceaModel'),
    schema: schemas.boolean(
      i18n('entities.models.fields.ceaModel'),
      {},
    ),
  },
  {
    name: 'bimModel',
    label: i18n('entities.models.fields.bimModel'),
    schema: schemas.boolean(
      i18n('entities.models.fields.bimModel'),
      {},
    ),
  },
  {
    name: 'result4',
    label: i18n('entities.models.fields.result4'),
    schema: schemas.boolean(
      i18n('entities.models.fields.result4'),
      {},
    ),
  },
  {
    name: 'acalabrutinib',
    label: i18n('entities.models.fields.acalabrutinib'),
    schema: schemas.boolean(
      i18n('entities.models.fields.acalabrutinib'),
      {},
    ),
  },
  {
    name: 'bendamustine',
    label: i18n('entities.models.fields.bendamustine'),
    schema: schemas.boolean(
      i18n('entities.models.fields.bendamustine'),
      {},
    ),
  },
  {
    name: 'duvelisib',
    label: i18n('entities.models.fields.duvelisib'),
    schema: schemas.boolean(
      i18n('entities.models.fields.duvelisib'),
      {},
    ),
  },
  {
    name: 'ibrutinib',
    label: i18n('entities.models.fields.ibrutinib'),
    schema: schemas.boolean(
      i18n('entities.models.fields.ibrutinib'),
      {},
    ),
  },
  {
    name: 'idelalisib',
    label: i18n('entities.models.fields.idelalisib'),
    schema: schemas.boolean(
      i18n('entities.models.fields.idelalisib'),
      {},
    ),
  },
  {
    name: 'lenalidomide',
    label: i18n('entities.models.fields.lenalidomide'),
    schema: schemas.boolean(
      i18n('entities.models.fields.lenalidomide'),
      {},
    ),
  },
  {
    name: 'obinutuzumab',
    label: i18n('entities.models.fields.obinutuzumab'),
    schema: schemas.boolean(
      i18n('entities.models.fields.obinutuzumab'),
      {},
    ),
  },
  {
    name: 'ofatumumab',
    label: i18n('entities.models.fields.ofatumumab'),
    schema: schemas.boolean(
      i18n('entities.models.fields.ofatumumab'),
      {},
    ),
  },
  {
    name: 'venetoclax',
    label: i18n('entities.models.fields.venetoclax'),
    schema: schemas.boolean(
      i18n('entities.models.fields.venetoclax'),
      {},
    ),
  },
  {
    name: 'result5',
    label: i18n('entities.models.fields.result5'),
    schema: schemas.boolean(
      i18n('entities.models.fields.result5'),
      {},
    ),
  },
  {
    name: 'ext1',
    label: i18n('entities.models.fields.ext1'),
    schema: schemas.string(
      i18n('entities.models.fields.ext1'),
      {},
    ),
  },
  {
    name: 'ext2',
    label: i18n('entities.models.fields.ext2'),
    schema: schemas.string(
      i18n('entities.models.fields.ext2'),
      {},
    ),
  },
  {
    name: 'ext3',
    label: i18n('entities.models.fields.ext3'),
    schema: schemas.string(
      i18n('entities.models.fields.ext3'),
      {},
    ),
  },
];