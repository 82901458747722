import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/secondaryCancers/importer/secondaryCancersImporterSelectors';
import SecondaryCancersService from 'src/modules/secondaryCancers/secondaryCancersService';
import fields from 'src/modules/secondaryCancers/importer/secondaryCancersImporterFields';
import { i18n } from 'src/i18n';

const secondaryCancersImporterActions = importerActions(
  'SECONDARYCANCERS_IMPORTER',
  selectors,
  SecondaryCancersService.import,
  fields,
  i18n('entities.secondaryCancers.importer.fileName'),
);

export default secondaryCancersImporterActions;