/**
 *
 * Creator: emids, inc.
 * Contact: lee.eldridge@emids.com
 * Version: 0.1
 *
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { i18n } from 'src/i18n';
import { init as i18nInit } from 'src/i18n';
import { AuthContextProvider } from 'src/store/auth-context';
import './index.scss';

(async function () {
  await i18nInit();

  const App = require('./App').default;
  document.title = i18n('app.title');
  ReactDOM.render(
    <AuthContextProvider>
      <App />
    </AuthContextProvider>, document.getElementById('root'));
})();
