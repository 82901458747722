import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import economicBurdenEnumerators from 'src/modules/economicBurden/economicBurdenEnumerators';

export default [
  {
    name: 'regionSel',
    label: i18n('entities.economicBurden.fields.regionSel'),
    schema: schemas.enumerator(
      i18n('entities.economicBurden.fields.regionSel'),
      {
        options: economicBurdenEnumerators.regionSel,
      },
    ),
  },
  {
    name: 'yearSel',
    label: i18n('entities.economicBurden.fields.yearSel'),
    schema: schemas.enumerator(
      i18n('entities.economicBurden.fields.yearSel'),
      {
        options: economicBurdenEnumerators.yearSel,
      },
    ),
  },
  {
    name: 'patientPopSel',
    label: i18n(
      'entities.economicBurden.fields.patientPopSel',
    ),
    schema: schemas.enumerator(
      i18n('entities.economicBurden.fields.patientPopSel'),
      {
        options: economicBurdenEnumerators.patientPopSel,
      },
    ),
  },
  {
    name: 'dataSourceSel',
    label: i18n(
      'entities.economicBurden.fields.dataSourceSel',
    ),
    schema: schemas.enumerator(
      i18n('entities.economicBurden.fields.dataSourceSel'),
      {
        options: economicBurdenEnumerators.dataSourceSel,
      },
    ),
  },
  {
    name: 'outcomeSel',
    label: i18n(
      'entities.economicBurden.fields.outcomeSel',
    ),
    schema: schemas.enumerator(
      i18n('entities.economicBurden.fields.outcomeSel'),
      {
        options: economicBurdenEnumerators.outcomeSel,
      },
    ),
  },
  {
    name: 'study',
    label: i18n('entities.economicBurden.fields.study'),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.study'),
      {},
    ),
  },
  {
    name: 'year',
    label: i18n('entities.economicBurden.fields.year'),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.year'),
      {},
    ),
  },
  {
    name: 'studyType',
    label: i18n('entities.economicBurden.fields.studyType'),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.studyType'),
      {},
    ),
  },
  {
    name: 'country',
    label: i18n('entities.economicBurden.fields.country'),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.country'),
      {},
    ),
  },
  {
    name: 'data',
    label: i18n('entities.economicBurden.fields.data'),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.data'),
      {},
    ),
  },
  {
    name: 'timePeriod',
    label: i18n(
      'entities.economicBurden.fields.timePeriod',
    ),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.timePeriod'),
      {},
    ),
  },
  {
    name: 'patientPopulation2',
    label: i18n(
      'entities.economicBurden.fields.patientPopulation2',
    ),
    schema: schemas.string(
      i18n(
        'entities.economicBurden.fields.patientPopulation2',
      ),
      {},
    ),
  },
  {
    name: 'economicEndpoints',
    label: i18n(
      'entities.economicBurden.fields.economicEndpoints',
    ),
    schema: schemas.string(
      i18n(
        'entities.economicBurden.fields.economicEndpoints',
      ),
      {},
    ),
  },
  {
    name: 'economicResults',
    label: i18n(
      'entities.economicBurden.fields.economicResults',
    ),
    schema: schemas.string(
      i18n(
        'entities.economicBurden.fields.economicResults',
      ),
      {},
    ),
  },
  {
    name: 'hruEndpoints',
    label: i18n(
      'entities.economicBurden.fields.hruEndpoints',
    ),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.hruEndpoints'),
      {},
    ),
  },
  {
    name: 'hruResults',
    label: i18n(
      'entities.economicBurden.fields.hruResults',
    ),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.hruResults'),
      {},
    ),
  },
  {
    name: 'count',
    label: i18n('entities.economicBurden.fields.count'),
    schema: schemas.integer(
      i18n('entities.economicBurden.fields.count'),
      {},
    ),
  },
  {
    name: 'rowIsVisible',
    label: i18n(
      'entities.economicBurden.fields.rowIsVisible',
    ),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.rowIsVisible'),
      {},
    ),
  },
  {
    name: 'rowColor',
    label: i18n('entities.economicBurden.fields.rowColor'),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.rowColor'),
      {},
    ),
  },
  {
    name: 'year3',
    label: i18n('entities.economicBurden.fields.year3'),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.year3'),
      {},
    ),
  },
  {
    name: 'africa',
    label: i18n('entities.economicBurden.fields.africa'),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.africa'),
      {},
    ),
  },
  {
    name: 'asiaPacific',
    label: i18n(
      'entities.economicBurden.fields.asiaPacific',
    ),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.asiaPacific'),
      {},
    ),
  },
  {
    name: 'australia',
    label: i18n('entities.economicBurden.fields.australia'),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.australia'),
      {},
    ),
  },
  {
    name: 'europe',
    label: i18n('entities.economicBurden.fields.europe'),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.europe'),
      {},
    ),
  },
  {
    name: 'latinAmerica',
    label: i18n(
      'entities.economicBurden.fields.latinAmerica',
    ),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.latinAmerica'),
      {},
    ),
  },
  {
    name: 'middleEast',
    label: i18n(
      'entities.economicBurden.fields.middleEast',
    ),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.middleEast'),
      {},
    ),
  },
  {
    name: 'usa',
    label: i18n('entities.economicBurden.fields.usa'),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.usa'),
      {},
    ),
  },
  {
    name: 'canada',
    label: i18n('entities.economicBurden.fields.canada'),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.canada'),
      {},
    ),
  },
  {
    name: 'result',
    label: i18n('entities.economicBurden.fields.result'),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.result'),
      {},
    ),
  },
  {
    name: 'cll',
    label: i18n('entities.economicBurden.fields.cll'),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.cll'),
      {},
    ),
  },
  {
    name: 'rrCll',
    label: i18n('entities.economicBurden.fields.rrCll'),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.rrCll'),
      {},
    ),
  },
  {
    name: 'claimsDatabase',
    label: i18n(
      'entities.economicBurden.fields.claimsDatabase',
    ),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.claimsDatabase'),
      {},
    ),
  },
  {
    name: 'eap',
    label: i18n('entities.economicBurden.fields.eap'),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.eap'),
      {},
    ),
  },
  {
    name: 'literature',
    label: i18n(
      'entities.economicBurden.fields.literature',
    ),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.literature'),
      {},
    ),
  },
  {
    name: 'medicalCenters',
    label: i18n(
      'entities.economicBurden.fields.medicalCenters',
    ),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.medicalCenters'),
      {},
    ),
  },
  {
    name: 'registry',
    label: i18n('entities.economicBurden.fields.registry'),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.registry'),
      {},
    ),
  },
  {
    name: 'result2',
    label: i18n('entities.economicBurden.fields.result2'),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.result2'),
      {},
    ),
  },
  {
    name: 'economicOutcome',
    label: i18n(
      'entities.economicBurden.fields.economicOutcome',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.economicBurden.fields.economicOutcome',
      ),
      {},
    ),
  },
  {
    name: 'hruOutcome',
    label: i18n(
      'entities.economicBurden.fields.hruOutcome',
    ),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.hruOutcome'),
      {},
    ),
  },
  {
    name: 'result3',
    label: i18n('entities.economicBurden.fields.result3'),
    schema: schemas.boolean(
      i18n('entities.economicBurden.fields.result3'),
      {},
    ),
  },
  {
    name: 'ext1',
    label: i18n('entities.economicBurden.fields.ext1'),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.ext1'),
      {},
    ),
  },
  {
    name: 'ext2',
    label: i18n('entities.economicBurden.fields.ext2'),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.ext2'),
      {},
    ),
  },
  {
    name: 'ext3',
    label: i18n('entities.economicBurden.fields.ext3'),
    schema: schemas.string(
      i18n('entities.economicBurden.fields.ext3'),
      {},
    ),
  },
];
